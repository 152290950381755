import './Seatmap.css';

import classNames from 'classnames';
import React, { useState } from 'react';

import {
  BodyType,
  VehicleLayout,
} from '@assured/shared-types/ClaimWorkflow/VehicleLayout';

import FiveSeat from './Seatmap/05Seat';
import EightSeat from './Seatmap/08Seat';
import TeslaSeat from './Seatmap/Tesla';

// Layouts by body type and number of seats. Default is mapped by zero for each type.
// TODO: Add doors as a further nested dimension when we have layouts to match.
const LAYOUTS = new Map<BodyType, Map<number, any>>([
  [
    BodyType.SEDAN,
    new Map<number, any>([
      [0, FiveSeat],
      [4, FiveSeat], // TODO: Replace with 4-seat overview
      [5, FiveSeat],
    ]),
  ],
  [
    BodyType.CAR, // Legacy values
    new Map<number, any>([
      [0, FiveSeat],
      [4, FiveSeat], // TODO: Replace with 4-seat overview
      [5, FiveSeat],
      [7, EightSeat],
      [8, EightSeat],
    ]),
  ],
  [
    BodyType.TESLA,
    new Map<number, any>([
      [0, TeslaSeat],
      [4, TeslaSeat], // TODO: Replace with 4-seat overview
      [5, TeslaSeat],
    ]),
  ],
  [
    BodyType.BOX_TRUCK,
    new Map<number, any>([
      [0, EightSeat],
      [8, EightSeat],
    ]),
  ],
  [
    BodyType.SUV,
    new Map<number, any>([
      [0, FiveSeat],
      [4, FiveSeat], // TODO: Replace with 4-seat overview
      [5, FiveSeat],
      [7, EightSeat],
      [8, EightSeat],
    ]),
  ],
]);

interface SeatmapProps {
  vehicleLayout: VehicleLayout;
  selected?: string[];
  allowAnySeat?: boolean;
  disabledSeats?: string[];
  small?: boolean;
  onClick?: (id: string) => void;
  labeled?: boolean;
}
const Seatmap: React.FC<SeatmapProps> = ({
  vehicleLayout,
  selected,
  allowAnySeat,
  disabledSeats,
  small,
  onClick,
  labeled,
}) => {
  // Generate Unique ID to prevent namespace conflict on style when multiple
  // <Seatmap/> rendered on page.
  const [id, _] = useState(
    `Seatmap_${Math.random().toString(36).substring(2, 15)}`,
  );
  const isTesla = vehicleLayout.body === BodyType.TESLA;
  const SELECTED_FILL = isTesla
    ? '#fff'
    : document.body.classList.contains('tenant-statefarm')
    ? 'var(--color-primary)'
    : '#3B82F6';

  const Inner =
    LAYOUTS.get(vehicleLayout.body)?.get(vehicleLayout.seats) ??
    LAYOUTS.get(vehicleLayout.body)?.get(0) ??
    FiveSeat;
  return (
    <div
      id={id}
      className={classNames(
        `Seatmap -mb-6 mx-auto`,
        onClick && 'interactive',
        small && 'SeatmapSmall',
        allowAnySeat && 'SeatmapAllowAnySeat',
        isTesla && (small ? '-mt-2 -mb-4' : '-mt-16 -mb-16'),
      )}
      onClick={
        onClick
          ? (e: React.MouseEvent<HTMLInputElement>) => {
              const el = (e.target as HTMLInputElement).closest('g[id]');
              if (el && el.id) {
                if (
                  (!allowAnySeat && el.id === 'DRIVER') ||
                  disabledSeats?.includes(el.id)
                ) {
                  // Don't allow selecting driver
                  return;
                }
                onClick(el.id);
              }
            }
          : undefined
      }
    >
      <Inner />
      <style>{`
        ${(selected || []).map(s => `#${id}.Seatmap #${s}`).join(', ')} {
          fill: ${SELECTED_FILL};
        }
      `}</style>
      <style>{`
        ${(disabledSeats || []).map(s => `#${id}.Seatmap #${s}`).join(', ')} {
          user-select: none;
          fill: var(--light-color);
          cursor: initial;
         }
      `}</style>
      <style>
        {
          // Without this margin:0, the seatmap doesn't properly report its
          // height, and the borders run through the diagram.
          labeled
            ? `#${id}.Seatmap {
          margin: 0px;
        }
      `
            : ''
        }
      </style>
    </div>
  );
};

export default Seatmap;
