import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import { googlePlaceToLocation } from '@assured/shared-types/Location/format';
import { LocationFragment } from '@assured/shared-types/Location/Location';
import { Coordinate } from '@assured/step-renderer/types/step-components/additional';
import { Autocomplete } from '@react-google-maps/api';

import { useLoadMaps } from '../../../hooks';
import Title from '../../ClaimWorkflow/Title';
import Modal from '../../Modal';
import Toggle from '../../Toggle';

export interface LocationSubmitData extends Partial<Coordinate> {
  businessGooglePlaceId?: string;
  addressText: string;
  line1: string;
  line2?: string;
  apartmentNumber: string | null;
  city: string | null;
  state: string | null;
  postalCode: string | null;
  addressComponents?: google.maps.GeocoderAddressComponent[];
}

interface LocationManualInputProps {
  noApartmentEntry?: boolean;
  noGpsEntry?: boolean;
  skipLabel?: string;
  onSkip?: () => void;
  autoSubmitInline?: boolean;
  initialAddressText?: string;
  initialValue?: LocationFragment;
  includeAddressComponents?: boolean;
  expected_countries?: string[];
  onDevice: () => void;
  onSubmit: (location: LocationSubmitData) => void;
  placeTypes?: string[];
  placeholder?: string;
  locationBias?: {
    latitude: number;
    longitude: number;
  };
}

const LocationManualInput: React.FC<LocationManualInputProps> = ({
  noApartmentEntry,
  noGpsEntry,
  autoSubmitInline,
  initialAddressText,
  initialValue,
  includeAddressComponents,
  skipLabel,
  onSkip,
  onDevice,
  onSubmit,
  expected_countries,
  placeTypes,
  placeholder = 'Start typing an address...',
  locationBias,
}) => {
  const { isLoaded } = useLoadMaps();
  const [place, setPlace] = useState<google.maps.places.PlaceResult | null>(
    null,
  );
  const [query, setQuery] = useState<string>(
    initialValue?.addressText || initialValue?.line1 || '',
  );
  const autocomplete = useRef<google.maps.places.Autocomplete | null>(null);

  const [isApartment, setIsApartment] = useState<boolean>(false);
  const [apartmentNumber, setApartmentNumber] = useState('');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [country, setCountry] = useState<
    google.maps.GeocoderAddressComponent | undefined
  >();
  const inputRef = useRef<HTMLInputElement>(null);

  const hasApartmentField = !noApartmentEntry;
  const canConfirm =
    (place || initialValue) &&
    (!hasApartmentField ||
      (isApartment !== null && (isApartment === false || apartmentNumber)));

  const onConfirm = () => {
    if (!canConfirm) {
      return;
    }
    if (expected_countries) {
      const country = place?.address_components?.find(x =>
        x.types.includes('country'),
      );
      setCountry(country);
      if (country && !expected_countries.includes(country.short_name)) {
        setShowConfirmationModal(true);
      } else {
        submit();
      }
    } else {
      submit();
    }
  };

  const submit = () => {
    if (!place && initialValue) {
      return onSubmit(initialValue as LocationSubmitData);
    }

    if (!place) {
      return;
    }

    onSubmit({
      apartmentNumber: null,
      ...googlePlaceToLocation({
        place,
        isApartment,
        apartmentNumber,
        includeAddressComponents,
        isNotEnriched: true,
      }),
      addressText: query,
    });
  };

  // Set any options on the autocomplete object (currently only place types)
  const setOptions = () => {
    if (placeTypes?.length && autocomplete.current) {
      autocomplete.current.setTypes(placeTypes);
      autocomplete.current.setOptions({
        types: placeTypes,
      });
    }

    if (locationBias) {
      autocomplete.current?.setBounds(
        // 25km radius
        new google.maps.LatLngBounds(
          new google.maps.LatLng(
            locationBias.latitude - 0.225,
            locationBias.longitude - 0.225,
          ),
          new google.maps.LatLng(
            locationBias.latitude + 0.225,
            locationBias.longitude + 0.225,
          ),
        ),
      );
    }
  };

  useEffect(setOptions, [JSON.stringify([placeTypes, locationBias])]);

  const AddressInput = (
    <input
      type="text"
      data-testid="manualLocationInput"
      className="textbox"
      placeholder={initialAddressText || (autoSubmitInline ? '' : placeholder)}
      aria-label="enter an address"
      ref={inputRef}
      value={query}
      onChange={e => setQuery(e.target.value)}
    />
  );

  return (
    <>
      <div className="mt-4">
        {isLoaded ? (
          <Autocomplete
            onLoad={ref => {
              autocomplete.current = ref;
              setOptions();
            }}
            onPlaceChanged={() => {
              if (autocomplete.current) {
                const place = autocomplete.current.getPlace();
                if (inputRef.current?.value && place) {
                  console.log('onChangeAutocomplete', inputRef.current?.value);
                  // Google will automatically update the input's value to match the text
                  // selected by the user, so we just need to reflect that change here to
                  // avoid flicker.
                  setQuery(inputRef.current?.value);
                }
                setPlace(
                  place.geometry ? autocomplete.current.getPlace() : null,
                );
                if (autoSubmitInline) {
                  setTimeout(() => onConfirm(), 100);
                }
              }
            }}
          >
            {AddressInput}
          </Autocomplete>
        ) : (
          <div>{AddressInput}</div>
        )}
        {hasApartmentField ? (
          <div className="mt-5 mb-5">
            <Title subtitle={'Is this an apartment?'} />
            <Toggle
              options={[
                { value: true, label: 'Yes' },
                { value: false, label: 'No' },
              ]}
              value={isApartment}
              onChange={v => typeof v === 'boolean' && setIsApartment(v)}
            />
            {isApartment ? (
              <div
                style={{ maxWidth: 200 }}
                className="mx-auto mt-3 text-center"
              >
                <input
                  type="text"
                  className="textbox ClaimWorkflowTitle"
                  placeholder="Apartment number"
                  value={apartmentNumber}
                  onChange={e => setApartmentNumber(e.target.value)}
                />
              </div>
            ) : null}
          </div>
        ) : null}
        {!autoSubmitInline ? (
          <div className="mt-4 md:flex md:flex-row md:flex-row-reverse justify-center">
            <button
              data-testid="primaryAction"
              className={classNames(
                `btn btn-blue`,
                !canConfirm && 'btn-disabled',
              )}
              onClick={onConfirm}
              disabled={!canConfirm}
            >
              Confirm location
            </button>
            {!noGpsEntry ? (
              <button
                className="btn btn-subtle"
                onClick={onDevice}
                data-testid="secondaryAction"
              >
                Switch to GPS
              </button>
            ) : null}
            {skipLabel && noGpsEntry ? (
              <button className="btn btn-subtle" onClick={onSkip}>
                {skipLabel}
              </button>
            ) : null}
          </div>
        ) : null}
      </div>
      <Modal
        title={'Confirm location'}
        body={
          <span>
            {`It looks like the location you entered is in ${country?.long_name}.  Is that correct?`}
          </span>
        }
        open={showConfirmationModal}
        customDismiss={() => setShowConfirmationModal(false)}
        actions={[
          {
            title: 'Enter a different location',
            primary: false,
            className: '',
            onClick: () => {
              setApartmentNumber('');
              setPlace(null);
              if (inputRef.current) {
                inputRef.current.value = '';
              }
              setShowConfirmationModal(false);
            },
          },
          {
            title: `Yes, the incident took place in ${country?.long_name}`,
            primary: true,
            className: '',
            onClick: () => {
              submit();
              setShowConfirmationModal(false);
            },
          },
        ]}
      />
    </>
  );
};

export default LocationManualInput;
