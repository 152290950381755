import type { TypePolicies } from '@apollo/client';

export const LOCAL_STORAGE_RECORDED_MIME_TYPE_KEY =
  'local-storage-recorded-mime-type-key';

export const FEATURE_FLAG_MORE_PHOTOS = 'guided-photo-capture-more-photos';
export const FEATURE_FLAG_OPTIONAL_GEOLOCATION =
  'guided-photo-capture-optional-geolocation';
export const FEATURE_FLAG_MACHINE_LEARNING =
  'guided_photo_capture_machine_learning';
export const FEATURE_FLAG_DETERMINISTIC_DEGRADED_STATE =
  'guided_photo_capture_deterministic_degraded_state';

export const FEATURE_FLAG_WEBM_VIDEOS_IN_GPC = 'webm-videos-in-gpc';

/**
 * Enable workaround of iOS Webkit bug introduced in 17.4. The feature flag
 * naming is unfortunate as we first identified it in Safari, but is also
 * reproduced now in Chrome.
 * @see {@link https://bugs.webkit.org/show_bug.cgi?id=269846 | WebKit Bugzilla Report}
 */
export const FEATURE_FLAG_WORKAROUND_IOS_WEBKIT_17_4 =
  'guided-photo-capture-workaround-ios-safari-17-4-bug';

export const TRACKING_EVENT_NAME_MEDIA_SAVE =
  'Guided Photo Capture: Media Save';

export const GPC_CAPTURE_RAW_VIDEO = 'GPC-capture-raw-video';

export const FALLBACK_VEHICLE_TYPE = 'sedan';

export const GUIDED_PHOTO_CAPTURE_APOLLO_TYPE_POLICIES: TypePolicies = {
  Media: {
    fields: {
      source: {
        merge(existing: string, incoming: string, opts) {
          // Reuse the existing source if available to prevent an image reload
          // with the new signed URL. This is safe because images themselves
          // are stable once they have been loaded. If the user uploads a new
          // image, it is a whole new Media entity.
          return existing ?? incoming;
        },
      },
    },
  },
};

/**
 * The maximimum number of regions in damage details
 */
export const MAX_REGIONS_IN_DAMAGE_DETAILS = 5;

/**
 * The maximimum number of regions in damage details, when requiring
 * a lower count.
 */
export const MAX_REGIONS_IN_DAMAGE_DETAILS_WITH_LOWER_LIMIT_FEATURE = 2;

/**
 * The maximimum number of regions to prompt for additional photos
 * per region.
 */
export const MAX_REGIONS_WITH_ADDITIONAL_PHOTOS = 2;

/**
 * The maximimum number of regions to prompt for additional photos
 * per region, when requiring a lower count.
 */
export const MAX_REGIONS_WITH_ADDITIONAL_PHOTOS_WITH_LOWER_LIMIT_FEATURE = 0;

/**
 * The minimum number of regions in damage details. If less, we use
 * generic copy.
 */
export const MIN_REGIONS_IN_DAMAGE_DETAILS = 2;

export const UPLOAD_INDEXED_DB_NAME = 'gpc-media-db';
export const UPLOAD_INDEXED_DB_STORE_NAME = 'gpc-media-store';
