import { kebabCase, lowerCase } from 'case-anything';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import {
  SelectMultiStepComponentSpec,
  StepComponentFC,
  StepComponentOtherValue,
  StepComponentSharedProps,
} from '@assured/step-renderer';

import { dataTestId } from '../../utilities/dataTestId';
import { formatHighlightedTerms } from '../ClaimWorkflow/Title';
import Icon from '../Icon';
import Toggle from '../Toggle';
import OtherModal from './OtherModal';

type SelectMultiProps = StepComponentSharedProps<
  SelectMultiStepComponentSpec,
  (string | boolean)[] | (boolean | string)
> &
  StepComponentOtherValue<string>;

// to track whether we can support char-based hotkeys in this view
// if greater than 1, we cannot start at a LUT zero-index
let totalInstances = 0;
let totalOptions = 0;

const SelectMulti: StepComponentFC<SelectMultiProps> = ({
  step_component,
  primaryValue,
  otherValue,
  updateValue,
  className,
}) => {
  const [showOtherInput, setShowOtherInput] = useState(false);
  useEffect(() => {
    if (
      Array.isArray(primaryValue) &&
      primaryValue.length === 0 &&
      step_component.empty_list_value
    ) {
      updateValue(step_component.field, [step_component.empty_list_value]);
    }
  }, [primaryValue]);

  // used to determine hotkey lookups at runtime
  const elOptions = useRef(new Array());

  // used to determine LUT indices
  let startingIndex = 0;
  const count = step_component?.options?.length ?? 0;
  const [countInstances, setCountInstances] = useState(0);

  // lookup-table used for indexing jump keys (36 char upper limit)
  const hotkeyLUT = '1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ';

  // simulates a hotkey press (if viable), when triggered
  const handleHotkeyPress = (e: React.KeyboardEvent) => {
    // TODO: bail if not using accessibility features

    // ignore if user is in an input
    const inputTypes = ['textarea', 'input'];
    const target = e?.target as HTMLElement;
    const isInput = inputTypes.includes(target?.tagName?.toLowerCase?.());
    if (isInput) return;

    const key = e?.key?.toUpperCase?.();
    const keyIndex = hotkeyLUT?.indexOf(key);

    // valid LUT range for current instance
    const min = startingIndex;
    const max = startingIndex + count;
    if (keyIndex < min || keyIndex > max) return;

    const elIndex = keyIndex - startingIndex;
    const el = elOptions?.current?.[elIndex];

    el?.click?.();
  };

  // this block is to used to ensure atomic (FIFO) counts of
  // like-component instances and their total number of options;
  // doing so allows us to bridge the hotkey index gap up to 36 items.
  // additionally, here is where we kickoff/teardown our listeners
  useEffect(() => {
    startingIndex = totalOptions;

    totalInstances++;
    totalOptions += count;

    setCountInstances(totalInstances);
    window.addEventListener('keyup', handleHotkeyPress);

    return () => {
      totalInstances--;
      totalOptions -= step_component?.options?.length ?? 0;

      window.removeEventListener('keyup', handleHotkeyPress);
    };
  }, []);

  // handler for allowing outer access of internal checkbox element;
  // increases clickable surface area – not directly related to hotkeys
  const handleTickInput = (e: React.KeyboardEvent | React.MouseEvent) => {
    e?.stopPropagation?.();

    let el = e?.target;
    let input: HTMLInputElement | undefined = undefined;
    while (!input && el) {
      input = el?.querySelector?.('input');
      el = el?.parentNode;
    }

    input?.click?.();
  };

  return (
    <div
      className={classNames(
        'mt-4 text-left',
        className,
        step_component.dense && 'flex flex-wrap md:grid md:grid-cols-2',
        step_component.big_icon_buttons && 'mx-auto',
      )}
      style={
        step_component.big_icon_buttons && !step_component.wide_big_icon_buttons
          ? { maxWidth: 200 }
          : {}
      }
    >
      <OtherModal
        onUpdate={v =>
          //FIXME(06-23-2020) Kinda stumped since this is the only place that doesn't fit the pattern
          step_component.other_field &&
          updateValue(step_component.other_field, v as any)
        }
        onSave={() => setShowOtherInput(false)}
        showInput={showOtherInput}
        prompt={step_component.other_prompt || 'Something else'}
        valueWrapper={step_component.other_value_wrapper}
        value={otherValue}
      />
      {step_component.options?.map(
        ({ value: v, label, icon, icon_text, testId }, i) => {
          const propValue =
            typeof primaryValue === 'undefined' || Array.isArray(primaryValue)
              ? primaryValue
              : [primaryValue];
          const selected = (propValue || []).indexOf(v || '') !== -1;

          const isOther = v === step_component.other_option;
          const displayLabel =
            (isOther &&
              (otherValue ||
                (step_component.other_existing_value as string))) ||
            label;

          const onCheckboxChange = (
            e: React.ChangeEvent<HTMLInputElement> | boolean,
          ) => {
            const set = new Set(step_component.single_toggle ? [] : propValue);
            if (
              v &&
              ((typeof e === 'boolean' && e) ||
                (typeof e !== 'boolean' && e.target.checked))
            ) {
              set.add(v);
              if (isOther) {
                setShowOtherInput(true);
              }
            } else if (v) {
              if (
                step_component.single_toggle &&
                typeof step_component.existing_value !== 'undefined' &&
                step_component.existing_value !== null &&
                !Array.isArray(step_component.existing_value)
              ) {
                set.add(step_component.existing_value);
              }
              set.delete(v);
            }
            if (set.size === 0 && step_component.single_toggle) {
              set.add(
                typeof step_component.single_toggle_unset_value !== 'undefined'
                  ? step_component.single_toggle_unset_value
                  : false,
              );
            }

            updateValue(
              step_component.field,
              step_component.single_toggle ? [...set][0] : [...set],
            );
          };

          if (step_component.mode === 'yes_no') {
            return (
              <div
                key={v?.toString()}
                className="flex items-center mb-4 py-4 px-6 rounded-md border border-cool-gray-200 bg-cool-gray-50 font-medium text-cool-gray-700"
              >
                <div className="flex-1">
                  {formatHighlightedTerms(displayLabel)}
                </div>
                <div>
                  <Toggle
                    options={[
                      { value: false, label: 'No' },
                      { value: true, label: 'Yes' },
                    ]}
                    value={selected}
                    onChange={onCheckboxChange}
                  />
                </div>
              </div>
            );
          }

          if (step_component.big_icon_buttons) {
            return (
              <div
                key={v?.toString()}
                tabIndex={0}
                ref={el => elOptions?.current?.push?.(el)}
                onClick={handleTickInput}
                onKeyUp={e => {
                  if (e?.key === 'Enter') handleTickInput(e);
                }}
                className={classNames(
                  '[&>*]:cursor-pointer flex flex-col relative border-solid border-2 rounded-lg p-2 px-4 hover:shadow cursor-pointer items-center transition duration-150 ease-in-out text-center focus:outline-none focus:border-blue-300 focus:shadow-outline-blue',
                  selected && 'bg-blue-100 border-blue-300',
                  step_component.wide_big_icon_buttons ? 'my-6' : 'my-8',
                )}
              >
                <input
                  data-testid={testId || dataTestId(label)}
                  tabIndex={-1}
                  type="checkbox"
                  aria-labelledby={`${kebabCase(displayLabel)}-label`}
                  className={classNames(
                    'absolute form-checkbox focus:shadow-none focus:border-gray-300 cursor-pointer h-8 w-8 text-blue-600 transition duration-150 ease-in-out transform',
                    selected && 'h-10 w-10',
                  )}
                  style={{
                    top: '-1rem',
                    right: '-1rem',
                    ...(selected && { transform: `rotate(7.5deg)` }),
                  }}
                  checked={selected}
                  onChange={onCheckboxChange}
                  onClick={e => e.stopPropagation()}
                />
                <Icon icon={icon} text={icon_text} />
                <label
                  id={`${kebabCase(displayLabel)}-label`}
                  className={classNames(
                    'text-cool-gray-600 leading-none text-lg',
                    !icon && !icon_text ? 'mt-2 mb-2' : 'mb-4',
                  )}
                >
                  {formatHighlightedTerms(displayLabel)}
                </label>

                {/* TODO: don't draw if accessibility features undetected */}
                {/*
              <span
                aria-label="hotkey"
                aria-describedby={`Press the "${hotkeyLUT[i + startingIndex]}" key to toggle this element`}
                className="bg-[lightblue] min-w-[24px] text-[12px] font-bold text-center box-content px-[3px] py-[8px] rounded-sm ml-[5px]"
              >
                {hotkeyLUT[i + startingIndex]}
              </span>
              */}
              </div>
            );
          }

          if (step_component.small_icon_buttons) {
            return (
              <div
                key={v?.toString()}
                tabIndex={0}
                ref={el => elOptions?.current?.push?.(el)}
                onClick={handleTickInput}
                onKeyUp={e => {
                  if (e?.key === 'Enter') handleTickInput(e);
                }}
                className={classNames(
                  '[&>*]:cursor-pointer flex max-h-16 border-solid border-2 rounded-lg hover:shadow cursor-pointer items-center transition duration-150 ease-in-out group focus:outline-none focus:border-blue-300 focus:shadow-outline-blue',
                  selected && 'bg-blue-100 border-blue-300',
                  step_component.dense ? 'm-2 flex-1' : 'my-4',
                )}
              >
                <div className="ml-4">
                  <Icon icon={icon} text={icon_text} />
                </div>
                <label
                  id={`${kebabCase(displayLabel)}-label`}
                  data-testid={testId || dataTestId(label)}
                  className="text-left flex-1 text-cool-gray-700"
                >
                  {formatHighlightedTerms(displayLabel)}
                </label>
                <input
                  tabIndex={-1}
                  type="checkbox"
                  aria-label={`option for ${lowerCase(displayLabel)}`}
                  className="form-checkbox h-5 w-5 mx-4 text-blue-600 transition duration-150 ease-in-out pointer-events-none"
                  checked={selected}
                  onChange={onCheckboxChange}
                />
              </div>
            );
          }

          return (
            <div
              key={v?.toString()}
              tabIndex={1}
              ref={el => elOptions?.current?.push?.(el)}
              onClick={handleTickInput}
              onKeyUp={e => {
                if (e?.key === 'Enter') handleTickInput(e);
              }}
              className={classNames(
                '[&>*]:cursor-pointer flex border-solid border-2 rounded-lg p-2 px-4 hover:shadow cursor-pointer items-center transition duration-150 ease-in-out group focus:outline-none focus:border-blue-300 focus:shadow-outline-blue',
                selected && 'bg-blue-100 border-blue-300',
                step_component.dense ? 'm-2 flex-1' : 'my-4',
              )}
            >
              <input
                tabIndex={-1}
                type="checkbox"
                aria-label={`option for ${lowerCase(displayLabel)}`}
                className="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out pointer-events-none"
                checked={selected}
                onChange={onCheckboxChange}
              />
              <label
                data-testid={testId || dataTestId(label)}
                className="text-left flex-1 text-cool-gray-700 ml-4"
              >
                {formatHighlightedTerms(displayLabel)}
              </label>

              {/* TODO: don't draw if accessibility features undetected */}
              {/*
            <span
              aria-label="hotkey"
              aria-describedby={`Press the "${hotkeyLUT[i + startingIndex]}" key to toggle this element`}
              className="bg-[lightblue] min-w-[24px] text-[12px] font-bold text-center box-content px-[3px] py-[8px] rounded-sm ml-[5px]"
            >
              {hotkeyLUT[i + startingIndex]}
            </span>
            */}
            </div>
          );
        },
      )}
    </div>
  );
};

SelectMulti.stepConfig = {
  manualSubmit: true,
};

export default SelectMulti;
