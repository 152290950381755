export type BodilyInjuryPerspective = 'front' | 'back';

export type BodilyInjuryRegion =
  | 'head'
  | 'shoulder_left'
  | 'shoulder_right'
  | 'arm_left'
  | 'arm_right'
  | 'hand_left'
  | 'hand_right'
  | 'chest'
  | 'torso'
  | 'upper_leg_left'
  | 'upper_leg_right'
  | 'lower_leg_left'
  | 'lower_leg_right'
  | 'foot_left'
  | 'foot_right'
  | 'back';

// 0 = No pain; 10 = Max pain
type PainRating = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;

export interface Region {
  key: BodilyInjuryRegion;
  options: {
    key: string;
    data: { [field: string]: boolean };
    manual_input?: string;
  }[];
  current_pain_rating?: PainRating;
}

export interface BodilyInjuryPayload {
  regions: Region[];
}

export type BodilyInjuryDisplayMode = 'workers_comp' | 'property';

const DisplayMode: { [k: string]: BodilyInjuryDisplayMode } = {
  WORKERS_COMP: 'workers_comp',
  PROPERTY: 'property',
};

export interface RegionTemplate {
  tabs: {
    key: string;
    label: string;
  }[];
  options: {
    key: string;
    tabs: string[];
    label: string;
    popular_in_modes?: BodilyInjuryDisplayMode[];
    hidden_in_modes?: BodilyInjuryDisplayMode[];
    hide_if_low_severity?: boolean;
    followups?: { key: string; label: string }[];
  }[];
}

export type BodilyInjuryRegionTemplates =
  | 'head'
  | 'shoulder'
  | 'arm'
  | 'hand'
  | 'chest'
  | 'torso'
  | 'back'
  | 'upper_leg'
  | 'lower_leg'
  | 'foot';

export const BodilyInjuryRegionToBodilyInjuryRegionTemplates: {
  [k in BodilyInjuryRegion]: BodilyInjuryRegionTemplates;
} = {
  head: 'head',
  shoulder_left: 'shoulder',
  shoulder_right: 'shoulder',
  arm_left: 'arm',
  arm_right: 'arm',
  hand_left: 'hand',
  hand_right: 'hand',
  chest: 'chest',
  torso: 'torso',
  upper_leg_left: 'upper_leg',
  upper_leg_right: 'upper_leg',
  lower_leg_left: 'lower_leg',
  lower_leg_right: 'lower_leg',
  foot_left: 'foot',
  foot_right: 'foot',
  back: 'back',
};

export const REGION_TEMPLATES: {
  [k in BodilyInjuryRegionTemplates]: RegionTemplate;
} = {
  head: {
    tabs: [
      {
        key: 'popular',
        label: 'Most common',
      },
      {
        key: 'head',
        label: 'Head',
      },
      {
        key: 'neck',
        label: 'Neck',
      },
      {
        key: 'ears',
        label: 'Ears',
      },
      {
        key: 'eyes',
        label: 'Eyes',
      },
      {
        key: 'skin',
        label: 'Skin',
      },
      {
        key: 'bleeding',
        label: 'Bleeding',
      },
      {
        key: 'jaw',
        label: 'Jaw/Mouth',
      },
    ],
    options: [
      {
        key: 'headache',
        label: 'Headache / migraine',
        popular_in_modes: [DisplayMode.WORKERS_COMP, DisplayMode.PROPERTY],
        tabs: ['popular', 'head'],
      },
      {
        key: 'concussion',
        label: 'Concussion',
        tabs: ['popular', 'head'],
        popular_in_modes: [DisplayMode.WORKERS_COMP, DisplayMode.PROPERTY],
        followups: [
          {
            key: 'loss_of_consciousness',
            label: 'Did this result in a loss of consciousness?',
          },
        ],
      },
      {
        key: 'skull',
        label: 'Skull fracture',
        tabs: ['head'],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'vision_loss',
        label: 'Vision loss',
        popular_in_modes: [DisplayMode.WORKERS_COMP],
        tabs: ['eyes'],
        followups: [
          {
            key: 'total_vision_loss',
            label: 'Was 100% of vision lost?',
          },
          {
            key: 'permanent_vision_loss',
            label: 'Was the vision loss permanent?',
          },
        ],
      },
      {
        key: 'eye_pain',
        label: 'Eye pain',
        tabs: ['eyes'],
      },
      {
        key: 'enucleation',
        label: 'Enucleation (eye removal or loss)',
        tabs: ['eyes'],
      },
      {
        key: 'eye_strain',
        label: 'Eye strain',
        tabs: ['eyes'],
      },
      {
        key: 'hearing_loss',
        label: 'Hearing loss',
        popular_in_modes: [DisplayMode.WORKERS_COMP],
        tabs: ['ears'],
        followups: [
          {
            key: 'total_hearing_loss',
            label: 'Was 100% of hearing lost?',
          },
          {
            key: 'permanent_hearing_loss',
            label: 'Was the hearing loss permanent?',
          },
        ],
      },
      {
        key: 'vestibular',
        label: 'Vestibular issues',
        tabs: ['ears'],
      },
      {
        key: 'eardrum',
        label: 'Burst eardrum',
        tabs: ['ears'],
      },
      {
        key: 'tinnitus',
        label: 'Tinnitus',
        tabs: ['ears'],
      },
      {
        key: 'bruise_head',
        label: 'Bruise',
        tabs: ['head'],
        followups: [
          {
            key: 'hematoma',
            label: 'Was the bruise a hematoma?',
          },
        ],
      },
      {
        key: 'brain_bleed',
        label: 'Brain bleed',
        tabs: ['head'],
      },
      {
        key: 'whiplash',
        label: 'Whiplash',
        hidden_in_modes: [DisplayMode.WORKERS_COMP, DisplayMode.PROPERTY],
        tabs: ['popular', 'neck'],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'vertebrae_fracture',
        label: 'Vertebrae fracture',
        popular_in_modes: [DisplayMode.WORKERS_COMP, DisplayMode.PROPERTY],
        tabs: ['neck'],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },

      {
        key: 'other_bone_head',
        label: 'Other broken bone',
        tabs: ['neck'],
      },

      {
        key: 'neck_pain',
        label: 'Neck pain',
        tabs: ['popular', 'neck'],
      },
      {
        key: 'cut_scrape',
        label: 'Cut / scrape',
        tabs: ['popular', 'skin'],
        popular_in_modes: [DisplayMode.PROPERTY],
        followups: [
          {
            key: 'stitches',
            label: 'Were stitches required?',
          },
        ],
      },
      {
        key: 'puncture',
        label: 'Puncture',
        tabs: ['neck'],
        followups: [
          {
            key: 'stitches',
            label: 'Were stitches required?',
          },
        ],
      },
      {
        key: 'airbag_burns',
        label: 'Airbag burns',
        hidden_in_modes: [DisplayMode.WORKERS_COMP, DisplayMode.PROPERTY],
        tabs: ['skin'],
      },

      {
        key: 'light_bleeding',
        label: 'Light bleeding',
        tabs: ['bleeding'],
      },
      {
        key: 'uncontrolled_bleeding',
        label: 'Uncontrolled bleeding',
        tabs: ['bleeding'],
        hide_if_low_severity: true,
        followups: [
          {
            key: 'transfusion',
            label: 'Was a blood transfusion performed?',
          },
        ],
      },
      {
        key: 'dislocated_jaw',
        label: 'Dislocated jaw',
        tabs: ['jaw'],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'broken_jaw',
        label: 'Broken jaw',
        tabs: ['jaw'],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'broken_teeth',
        label: 'Broken teeth',
        tabs: ['jaw'],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'lacerated_tongue',
        label: 'Lacerated tongue',
        tabs: ['jaw'],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
    ],
  },
  shoulder: {
    tabs: [
      {
        key: 'popular',
        label: 'Most common',
      },
      {
        key: 'skin',
        label: 'Skin',
      },
      {
        key: 'joint',
        label: 'Joint',
      },
      {
        key: 'bone',
        label: 'Bone',
      },
      {
        key: 'bleeding',
        label: 'Bleeding',
      },
    ],
    options: [
      {
        key: 'cut_scrape',
        label: 'Cut / scrape',
        tabs: ['popular', 'skin'],
        popular_in_modes: [DisplayMode.WORKERS_COMP, DisplayMode.PROPERTY],
        followups: [
          {
            key: 'stitches',
            label: 'Were stitches required?',
          },
        ],
      },
      {
        key: 'bruise',
        label: 'Bruise',
        tabs: ['skin'],
        followups: [
          {
            key: 'major',
            label: 'Was this a major bruise?',
          },
        ],
      },
      {
        key: 'puncture',
        label: 'Puncture',
        tabs: ['skin'],
        followups: [
          {
            key: 'stitches',
            label: 'Were stitches required?',
          },
        ],
      },
      {
        key: 'airbag_burns',
        label: 'Airbag burns',
        hidden_in_modes: [DisplayMode.WORKERS_COMP, DisplayMode.PROPERTY],
        tabs: ['skin'],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'dislocated',
        label: 'Dislocated shoulder',
        tabs: ['joint', 'popular'],
        popular_in_modes: [DisplayMode.WORKERS_COMP],
      },
      {
        key: 'hyperextension',
        label: 'Hyperextension',
        popular_in_modes: [DisplayMode.WORKERS_COMP],
        tabs: ['joint'],
      },
      {
        key: 'sprain_strain',
        label: 'Sprain / strain',
        tabs: ['joint', 'popular'],
        popular_in_modes: [DisplayMode.WORKERS_COMP],
      },
      {
        key: 'soreness',
        label: 'Muscle soreness / generalized pain',
        tabs: ['joint', 'popular'],
        popular_in_modes: [DisplayMode.WORKERS_COMP, DisplayMode.PROPERTY],
      },

      {
        key: 'broken_bone',
        label: 'Broken bone',
        tabs: ['bone'],
        popular_in_modes: [DisplayMode.PROPERTY],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'bruised_bone',
        label: 'Bruised bone',
        tabs: ['bone'],
      },
      {
        key: 'light_bleeding',
        label: 'Light bleeding',
        tabs: ['bleeding'],
      },
      {
        key: 'uncontrolled_bleeding',
        label: 'Uncontrolled bleeding',
        tabs: ['bleeding'],
        hide_if_low_severity: true,
        followups: [
          {
            key: 'transfusion',
            label: 'Was a blood transfusion performed?',
          },
        ],
      },
    ],
  },
  arm: {
    tabs: [
      {
        key: 'popular',
        label: 'Most common',
      },
      {
        key: 'skin',
        label: 'Skin',
      },
      {
        key: 'elbow',
        label: 'Elbow',
      },
      {
        key: 'bone',
        label: 'Bone',
      },
      {
        key: 'bleeding',
        label: 'Bleeding',
      },
    ],
    options: [
      {
        key: 'cut_scrape',
        label: 'Cut / scrape',
        tabs: ['popular', 'skin'],
        popular_in_modes: [DisplayMode.WORKERS_COMP, DisplayMode.PROPERTY],
        followups: [
          {
            key: 'stitches',
            label: 'Were stitches required?',
          },
        ],
      },
      {
        key: 'bruise',
        label: 'Bruise',
        tabs: ['skin'],
        followups: [
          {
            key: 'major',
            label: 'Was this a major bruise?',
          },
        ],
      },
      {
        key: 'puncture',
        label: 'Puncture',
        tabs: ['skin'],
        followups: [
          {
            key: 'stitches',
            label: 'Were stitches required?',
          },
        ],
      },
      {
        key: 'airbag_burns',
        label: 'Airbag burns',
        hidden_in_modes: [DisplayMode.WORKERS_COMP, DisplayMode.PROPERTY],
        tabs: ['skin'],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'light_bleeding',
        label: 'Light bleeding',
        tabs: ['bleeding'],
      },
      {
        key: 'uncontrolled_bleeding',
        label: 'Uncontrolled bleeding',
        tabs: ['bleeding'],
        hide_if_low_severity: true,
        followups: [
          {
            key: 'transfusion',
            label: 'Was a blood transfusion performed?',
          },
        ],
      },
      {
        key: 'dislocated',
        label: 'Dislocated elbow',
        tabs: ['elbow', 'popular'],
      },
      {
        key: 'hyperextension',
        label: 'Hyperextension',
        popular_in_modes: [DisplayMode.WORKERS_COMP],
        tabs: ['elbow'],
      },
      {
        key: 'sprain_strain',
        label: 'Sprain / strain',
        popular_in_modes: [DisplayMode.WORKERS_COMP],
        tabs: ['elbow', 'popular'],
      },
      {
        key: 'soreness',
        label: 'Muscle soreness / generalized pain',
        tabs: ['elbow', 'popular'],
      },
      {
        key: 'broken_bone',
        label: 'Broken bone',
        tabs: ['bone', 'popular'],
        popular_in_modes: [DisplayMode.WORKERS_COMP, DisplayMode.PROPERTY],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'bruised_bone',
        label: 'Bruised bone',
        tabs: ['bone'],
      },
      {
        key: 'amputation',
        label: 'Amputation',
        popular_in_modes: [DisplayMode.WORKERS_COMP],
        tabs: ['bone'],
        hide_if_low_severity: true,
      },
    ],
  },
  hand: {
    tabs: [
      {
        key: 'popular',
        label: 'Most common',
      },
      {
        key: 'skin',
        label: 'Skin',
      },
      {
        key: 'wrist',
        label: 'Wrist',
      },
      {
        key: 'palm',
        label: 'Palm',
      },
      {
        key: 'finger',
        label: 'Fingers',
      },
      {
        key: 'bleeding',
        label: 'Bleeding',
      },
    ],
    options: [
      {
        key: 'cut_scrape',
        label: 'Cut / scrape',
        tabs: ['popular', 'skin'],
        popular_in_modes: [DisplayMode.PROPERTY],
        followups: [
          {
            key: 'stitches',
            label: 'Were stitches required?',
          },
        ],
      },
      {
        key: 'bruise',
        label: 'Bruise',
        tabs: ['skin'],
        followups: [
          {
            key: 'major',
            label: 'Was this a major bruise?',
          },
        ],
      },
      {
        key: 'puncture',
        label: 'Puncture',
        tabs: ['skin', 'popular'],
        popular_in_modes: [DisplayMode.WORKERS_COMP, DisplayMode.PROPERTY],
        followups: [
          {
            key: 'stitches',
            label: 'Were stitches required?',
          },
        ],
      },
      {
        key: 'airbag_burns',
        label: 'Airbag burns',
        hidden_in_modes: [DisplayMode.WORKERS_COMP, DisplayMode.PROPERTY],
        tabs: ['skin'],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'light_bleeding',
        label: 'Light bleeding',
        tabs: ['bleeding'],
      },
      {
        key: 'uncontrolled_bleeding',
        label: 'Uncontrolled bleeding',
        tabs: ['bleeding'],
        hide_if_low_severity: true,
        followups: [
          {
            key: 'transfusion',
            label: 'Was a blood transfusion performed?',
          },
        ],
      },
      {
        key: 'broken_finger',
        label: 'Broken finger',
        popular_in_modes: [DisplayMode.WORKERS_COMP],
        tabs: ['finger', 'popular'],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'hyperextension_finger',
        label: 'Hyperextended finger',
        popular_in_modes: [DisplayMode.WORKERS_COMP],
        tabs: ['finger', 'popular'],
      },
      {
        key: 'sprain_finger',
        label: 'Sprained / strained finger',
        tabs: ['finger'],
      },
      {
        key: 'amputation_finger',
        label: 'Amputated finger',
        popular_in_modes: [DisplayMode.WORKERS_COMP],
        tabs: ['finger'],
        hide_if_low_severity: true,
      },
      {
        key: 'broken_wrist',
        label: 'Broken wrist',
        popular_in_modes: [DisplayMode.WORKERS_COMP, DisplayMode.PROPERTY],
        tabs: ['wrist'],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'bruised_bone',
        label: 'Bruised bone',
        tabs: ['palm'],
      },
      {
        key: 'broken_bone',
        label: 'Broken bone',
        tabs: ['palm'],
      },
      {
        key: 'amputation_hand',
        label: 'Amputated hand',
        tabs: ['palm'],
        hide_if_low_severity: true,
      },
      {
        key: 'hyperextension_wrist',
        label: 'Hyperextended wrist',
        tabs: ['wrist'],
      },
      {
        key: 'sprain_wrist',
        label: 'Sprained / strained wrist',
        tabs: ['popular', 'wrist'],
        popular_in_modes: [DisplayMode.WORKERS_COMP],
      },
      {
        key: 'soreness',
        label: 'Muscle soreness / generalized pain',
        tabs: ['wrist'],
      },
    ],
  },
  chest: {
    tabs: [
      {
        key: 'popular',
        label: 'Most common',
      },
      {
        key: 'skin',
        label: 'Skin',
      },
      {
        key: 'ribs',
        label: 'Ribs',
      },
      {
        key: 'collarbone',
        label: 'Collarbone',
      },
      {
        key: 'bleeding',
        label: 'Bleeding',
      },
    ],
    options: [
      {
        key: 'cut_scrape',
        label: 'Cut / scrape',
        tabs: ['skin'],
        followups: [
          {
            key: 'stitches',
            label: 'Were stitches required?',
          },
        ],
      },
      {
        key: 'bruise',
        label: 'Bruise',
        tabs: ['skin'],
        followups: [
          {
            key: 'major',
            label: 'Was this a major bruise?',
          },
        ],
      },
      {
        key: 'puncture',
        label: 'Puncture',
        tabs: ['skin', 'popular'],
        popular_in_modes: [DisplayMode.WORKERS_COMP, DisplayMode.PROPERTY],
        followups: [
          {
            key: 'organs',
            label: 'Were organs damaged?',
          },
        ],
      },
      {
        key: 'airbag_burns',
        label: 'Airbag burns',
        hidden_in_modes: [DisplayMode.WORKERS_COMP, DisplayMode.PROPERTY],
        tabs: ['skin'],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'seatbelt_bruise',
        label: 'Seatbelt bruise',
        hidden_in_modes: [DisplayMode.WORKERS_COMP, DisplayMode.PROPERTY],
        tabs: ['skin'],
      },
      {
        key: 'light_bleeding',
        label: 'Light bleeding',
        tabs: ['bleeding'],
      },
      {
        key: 'uncontrolled_bleeding',
        label: 'Uncontrolled bleeding',
        tabs: ['bleeding'],
        hide_if_low_severity: true,
        followups: [
          {
            key: 'transfusion',
            label: 'Was a blood transfusion performed?',
          },
        ],
      },
      {
        key: 'broken_rib',
        label: 'Broken rib',
        tabs: ['ribs', 'popular'],
        popular_in_modes: [DisplayMode.WORKERS_COMP, DisplayMode.PROPERTY],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'bruised_rib',
        label: 'Bruised rib',
        tabs: ['ribs', 'popular'],
      },
      {
        key: 'broken_collarbone',
        label: 'Broken collarbone',
        tabs: ['collarbone', 'popular'],
        popular_in_modes: [DisplayMode.WORKERS_COMP],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'bruised_collarbone',
        label: 'Bruised collarbone',
        tabs: ['collarbone'],
      },
      {
        key: 'soreness',
        label: 'Muscle soreness / generalized pain',
        tabs: ['popular'],
      },
    ],
  },
  torso: {
    tabs: [
      {
        key: 'popular',
        label: 'Most common',
      },
      {
        key: 'skin',
        label: 'Skin',
      },
      {
        key: 'ribs',
        label: 'Ribs',
      },
      {
        key: 'groin',
        label: 'Groin',
      },
      {
        key: 'bleeding',
        label: 'Bleeding',
      },
    ],
    options: [
      {
        key: 'cut_scrape',
        label: 'Cut / scrape',
        tabs: ['skin'],
        followups: [
          {
            key: 'stitches',
            label: 'Were stitches required?',
          },
        ],
      },
      {
        key: 'bruise',
        label: 'Bruise',
        tabs: ['skin'],
        followups: [
          {
            key: 'major',
            label: 'Was this a major bruise?',
          },
        ],
      },
      {
        key: 'puncture',
        label: 'Puncture',
        tabs: ['skin', 'popular'],
        popular_in_modes: [DisplayMode.WORKERS_COMP, DisplayMode.PROPERTY],
        followups: [
          {
            key: 'organs',
            label: 'Were organs damaged?',
          },
        ],
      },
      {
        key: 'airbag_burns',
        label: 'Airbag burns',
        tabs: ['skin'],
        hidden_in_modes: [DisplayMode.WORKERS_COMP, DisplayMode.PROPERTY],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'seatbelt_bruise',
        label: 'Seatbelt bruise',
        hidden_in_modes: [DisplayMode.WORKERS_COMP, DisplayMode.PROPERTY],
        tabs: ['skin'],
      },
      {
        key: 'light_bleeding',
        label: 'Light bleeding',
        tabs: ['bleeding'],
      },
      {
        key: 'uncontrolled_bleeding',
        label: 'Uncontrolled bleeding',
        tabs: ['bleeding'],
        hide_if_low_severity: true,
        followups: [
          {
            key: 'transfusion',
            label: 'Was a blood transfusion performed?',
          },
        ],
      },
      {
        key: 'broken_rib',
        label: 'Broken rib',
        tabs: ['ribs', 'popular'],
        popular_in_modes: [DisplayMode.WORKERS_COMP, DisplayMode.PROPERTY],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'bruised_rib',
        label: 'Bruised rib',
        tabs: ['ribs', 'popular'],
      },

      {
        key: 'hernia',
        label: 'Abdominal hernia',
        popular_in_modes: [DisplayMode.WORKERS_COMP],
        tabs: ['groin'],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'genital_damage',
        label: 'Genital damage',
        popular_in_modes: [DisplayMode.WORKERS_COMP, DisplayMode.PROPERTY],
        tabs: ['groin', 'popular'],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'soreness',
        label: 'Muscle soreness / generalized pain',
        tabs: ['popular'],
      },
    ],
  },
  back: {
    tabs: [
      {
        key: 'popular',
        label: 'Most common',
      },
    ],
    options: [
      {
        key: 'vertebrae_fracture',
        label: 'Vertebrae fracture',
        tabs: ['popular'],
        popular_in_modes: [DisplayMode.WORKERS_COMP, DisplayMode.PROPERTY],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'back_sprain',
        label: 'Sprain/strain',
        popular_in_modes: [DisplayMode.WORKERS_COMP, DisplayMode.PROPERTY],
        tabs: ['popular'],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'herniated_disk',
        label: 'Herniated disk',
        popular_in_modes: [DisplayMode.WORKERS_COMP, DisplayMode.PROPERTY],
        tabs: ['popular'],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'back_nerve_pinch',
        label: 'Pinched nerve',
        popular_in_modes: [DisplayMode.WORKERS_COMP, DisplayMode.PROPERTY],
        tabs: ['popular'],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'spinal_nerve_damage',
        label: 'Spinal nerve damage',
        popular_in_modes: [DisplayMode.WORKERS_COMP, DisplayMode.PROPERTY],
        tabs: ['popular'],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
          {
            key: 'paralysis',
            label: 'Is full or partial paralysis currently present?',
          },
        ],
      },
      {
        key: 'soreness',
        label: 'Muscle soreness / generalized pain',
        tabs: ['popular'],
      },
    ],
  },
  upper_leg: {
    tabs: [
      {
        key: 'popular',
        label: 'Most common',
      },
      {
        key: 'skin',
        label: 'Skin',
      },
      {
        key: 'hip',
        label: 'Hip joint',
      },
      {
        key: 'bone',
        label: 'Bone',
      },
      {
        key: 'bleeding',
        label: 'Bleeding',
      },
    ],
    options: [
      {
        key: 'cut_scrape',
        label: 'Cut / scrape',
        tabs: ['popular', 'skin'],
        popular_in_modes: [DisplayMode.PROPERTY],
        followups: [
          {
            key: 'stitches',
            label: 'Were stitches required?',
          },
        ],
      },
      {
        key: 'bruise',
        label: 'Bruise',
        tabs: ['skin'],
        followups: [
          {
            key: 'major',
            label: 'Was this a major bruise?',
          },
        ],
      },
      {
        key: 'puncture',
        label: 'Puncture',
        tabs: ['skin', 'popular'],
        popular_in_modes: [DisplayMode.WORKERS_COMP, DisplayMode.PROPERTY],
        followups: [
          {
            key: 'stitches',
            label: 'Were stitches required?',
          },
        ],
      },
      {
        key: 'light_bleeding',
        label: 'Light bleeding',
        tabs: ['bleeding'],
      },
      {
        key: 'uncontrolled_bleeding',
        label: 'Uncontrolled bleeding',
        tabs: ['bleeding'],
        hide_if_low_severity: true,
        followups: [
          {
            key: 'transfusion',
            label: 'Was a blood transfusion performed?',
          },
        ],
      },
      {
        key: 'dislocated_hip',
        label: 'Dislocated hip',
        tabs: ['hip', 'popular'],
        popular_in_modes: [DisplayMode.WORKERS_COMP],
      },
      {
        key: 'hyperextension_hip',
        label: 'Hyperextended hip',
        tabs: ['hip'],
      },
      {
        key: 'sprain_strain',
        label: 'Sprain / strain',
        tabs: ['hip', 'popular'],
      },
      {
        key: 'soreness',
        label: 'Muscle soreness / generalized pain',
        tabs: ['hip', 'popular'],
      },

      {
        key: 'broken_bone',
        label: 'Broken bone',
        tabs: ['bone', 'popular'],
        popular_in_modes: [DisplayMode.WORKERS_COMP, DisplayMode.PROPERTY],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'bruised_bone',
        label: 'Bruised bone',
        tabs: ['bone'],
      },
      {
        key: 'amputation',
        label: 'Amputation',
        popular_in_modes: [DisplayMode.WORKERS_COMP],
        tabs: ['hip'],
        hide_if_low_severity: true,
      },
    ],
  },
  lower_leg: {
    tabs: [
      {
        key: 'popular',
        label: 'Most common',
      },
      {
        key: 'skin',
        label: 'Skin',
      },
      {
        key: 'knee',
        label: 'Knee',
      },
      {
        key: 'bone',
        label: 'Bone',
      },
      {
        key: 'bleeding',
        label: 'Bleeding',
      },
    ],
    options: [
      {
        key: 'cut_scrape',
        label: 'Cut / scrape',
        tabs: ['popular', 'skin'],
        popular_in_modes: [DisplayMode.PROPERTY],
        followups: [
          {
            key: 'stitches',
            label: 'Were stitches required?',
          },
        ],
      },
      {
        key: 'bruise',
        label: 'Bruise',
        tabs: ['skin'],
        followups: [
          {
            key: 'major',
            label: 'Was this a major bruise?',
          },
        ],
      },
      {
        key: 'puncture',
        label: 'Puncture',
        tabs: ['skin'],
        followups: [
          {
            key: 'stitches',
            label: 'Were stitches required?',
          },
        ],
      },
      {
        key: 'light_bleeding',
        label: 'Light bleeding',
        tabs: ['bleeding'],
      },
      {
        key: 'uncontrolled_bleeding',
        label: 'Uncontrolled bleeding',
        tabs: ['bleeding'],
        hide_if_low_severity: true,
        followups: [
          {
            key: 'transfusion',
            label: 'Was a blood transfusion performed?',
          },
        ],
      },
      {
        key: 'dislocated_knee',
        label: 'Dislocated knee',
        tabs: ['knee', 'popular'],
        popular_in_modes: [DisplayMode.WORKERS_COMP],
      },
      {
        key: 'hyperextension_knee',
        label: 'Hyperextended knee',
        tabs: ['knee'],
      },
      {
        key: 'sprain_strain',
        label: 'Sprain / strain',
        tabs: ['knee', 'popular'],
        popular_in_modes: [DisplayMode.WORKERS_COMP],
      },
      {
        key: 'soreness',
        label: 'Muscle soreness / generalized pain',
        tabs: ['knee', 'popular'],
      },
      {
        key: 'acl_meniscus',
        label: 'Torn ACL / meniscus',
        tabs: ['popular', 'knee'],
        popular_in_modes: [DisplayMode.WORKERS_COMP],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'broken_bone',
        label: 'Broken bone',
        tabs: ['bone', 'popular'],
        popular_in_modes: [DisplayMode.WORKERS_COMP, DisplayMode.PROPERTY],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'bruised_bone',
        label: 'Bruised bone',
        tabs: ['bone'],
        popular_in_modes: [DisplayMode.PROPERTY],
      },
      {
        key: 'amputation',
        label: 'Amputation',
        popular_in_modes: [DisplayMode.WORKERS_COMP],
        tabs: ['bone'],
        hide_if_low_severity: true,
      },
    ],
  },
  foot: {
    tabs: [
      {
        key: 'popular',
        label: 'Most common',
      },
      {
        key: 'skin',
        label: 'Skin',
      },
      {
        key: 'foot',
        label: 'Foot',
      },
      {
        key: 'toes',
        label: 'Toes',
      },
      {
        key: 'bleeding',
        label: 'Bleeding',
      },
      {
        key: 'ankle',
        label: 'Ankle',
      },
    ],
    options: [
      {
        key: 'cut_scrape',
        label: 'Cut / scrape',
        tabs: ['skin'],
        popular_in_modes: [DisplayMode.PROPERTY],
        followups: [
          {
            key: 'stitches',
            label: 'Were stitches required?',
          },
        ],
      },
      {
        key: 'bruise',
        label: 'Bruise',
        tabs: ['skin'],
        followups: [
          {
            key: 'major',
            label: 'Was this a major bruise?',
          },
        ],
      },
      {
        key: 'puncture',
        label: 'Puncture',
        tabs: ['skin'],
        followups: [
          {
            key: 'stitches',
            label: 'Were stitches required?',
          },
        ],
      },
      {
        key: 'light_bleeding',
        label: 'Light bleeding',
        tabs: ['bleeding'],
      },
      {
        key: 'uncontrolled_bleeding',
        label: 'Uncontrolled bleeding',
        tabs: ['bleeding'],
        hide_if_low_severity: true,
        followups: [
          {
            key: 'transfusion',
            label: 'Was a blood transfusion performed?',
          },
        ],
      },
      {
        key: 'broken_toe',
        label: 'Broken toe',
        tabs: ['toes', 'popular'],
        popular_in_modes: [DisplayMode.WORKERS_COMP, DisplayMode.PROPERTY],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'hyperextension_toe',
        label: 'Hyperextended toe',
        tabs: ['toes'],
      },
      {
        key: 'sprain_strain',
        label: 'Sprain / strain',
        tabs: ['toes', 'ankle', 'popular'],
        popular_in_modes: [DisplayMode.WORKERS_COMP],
      },
      {
        key: 'soreness',
        label: 'Muscle soreness / generalized pain',
        tabs: ['ankle', 'foot'],
      },
      {
        key: 'amputation_toe',
        label: 'Amputated toe',
        tabs: ['toes'],
        hide_if_low_severity: true,
      },
      {
        key: 'broken_ankle',
        label: 'Broken ankle',
        tabs: ['popular', 'ankle'],
        popular_in_modes: [DisplayMode.WORKERS_COMP, DisplayMode.PROPERTY],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'hyperextension_ankle',
        label: 'Hyperextended ankle',
        tabs: ['ankle'],
      },
      {
        key: 'broken_bone',
        label: 'Broken bone',
        tabs: ['foot'],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'bruised_bone',
        label: 'Bruised bone',
        tabs: ['foot', 'popular'],
      },
      {
        key: 'amputation_foot',
        label: 'Amputated foot',
        tabs: ['foot'],
        hide_if_low_severity: true,
      },
    ],
  },
};

export const BODILY_INJURY_REGIONS = [
  {
    key: 'head',
    label: 'Head',
    data: REGION_TEMPLATES.head,
  },
  {
    key: 'shoulder_left',
    label: 'Left shoulder',
    data: REGION_TEMPLATES.shoulder,
  },
  {
    key: 'shoulder_right',
    label: 'Right shoulder',
    data: REGION_TEMPLATES.shoulder,
  },
  {
    key: 'arm_left',
    label: 'Left arm',
    data: REGION_TEMPLATES.arm,
  },
  {
    key: 'arm_right',
    label: 'Right arm',
    data: REGION_TEMPLATES.arm,
  },
  {
    key: 'hand_left',
    label: 'Left hand',
    data: REGION_TEMPLATES.hand,
  },
  {
    key: 'hand_right',
    label: 'Right hand',
    data: REGION_TEMPLATES.hand,
  },
  {
    key: 'chest',
    label: 'Chest',
    data: REGION_TEMPLATES.chest,
  },
  {
    key: 'torso',
    label: 'Torso',
    data: REGION_TEMPLATES.torso,
  },
  {
    key: 'upper_leg_left',
    label: 'Upper left leg',
    data: REGION_TEMPLATES.upper_leg,
  },
  {
    key: 'upper_leg_right',
    label: 'Upper right leg',
    data: REGION_TEMPLATES.upper_leg,
  },
  {
    key: 'lower_leg_left',
    label: 'Lower left leg',
    data: REGION_TEMPLATES.lower_leg,
  },
  {
    key: 'lower_leg_right',
    label: 'Lower right leg',
    data: REGION_TEMPLATES.lower_leg,
  },
  {
    key: 'foot_left',
    label: 'Left foot',
    data: REGION_TEMPLATES.foot,
  },
  {
    key: 'foot_right',
    label: 'Right foot',
    data: REGION_TEMPLATES.foot,
  },
  {
    key: 'back',
    label: 'Back',
    data: REGION_TEMPLATES.back,
  },
] as const;
