import classNames from 'classnames';
import { isEmpty, isPlainObject } from 'lodash';
import React from 'react';

const ReactAnimatedWeather =
  typeof window !== 'undefined'
    ? require('react-animated-weather').default
    : () => null;

export const WeatherForecast: React.FC<{
  className?: string;
  small?: true;
  forecast: any;
}> = ({ forecast, small, className }) => {
  if (!isPlainObject(forecast) || isEmpty(forecast) || !forecast?.icon) {
    return null;
  }
  const iconKey = forecast.icon.toUpperCase().replace(/-/g, '_');
  const useWhiteColor = document.body.classList.contains('tenant-tesla');

  const forecastLabel = `Animated illustration to describe a forecast of "${forecast?.icon?.toLowerCase?.()}"`;

  return (
    <>
      <div
        data-not-copyable
        className={classNames('mt-4 mb-4 text-center w-full', className)}
      >
        {/*
          The "react-animated-weather" package does not provide a way to
          markup its contents for screen readers (uses <canvas>
          internally).
 
          The WAI-ARIA format allows us to "typecast" this to an "img"
          (via the "role" prop) and then use an "aria-label" to describe
          the imagery to screen reader users.
        */}
        <div
          role="img"
          aria-label={forecastLabel}
          className="mx-auto"
          style={{ maxWidth: 120 }}
        >
          <ReactAnimatedWeather
            color={useWhiteColor ? '#fff' : '#718096'}
            icon={iconKey}
            size={120}
            animate={iconKey.indexOf('NIGHT') === -1} // Moon animation issues on mobile.
          />
        </div>
        <div className={`${small ? 'text-l' : 'text-2xl'}`}>
          {forecast.summary}
        </div>
        <div className={`${small ? 'text-m' : 'text-xl'}`}>
          {forecast.temperature.toFixed(0)}°F
        </div>
      </div>
    </>
  );
};
export default WeatherForecast;
