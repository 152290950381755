import { ReactNode } from 'react';

import { IconFlatRenderer } from '@assured/design-system/src/components/Icon/IconFlatRenderer';
import { PartyEntryDataEntry } from '@assured/step-renderer/types/step-components/PartyEntry';
import { capitalize } from '@assured/utilities/src/capitalize';

type PartyEntryCommonProps = {
  icon: ReactNode;
  title: string;
  description: string | null;
};

type PartyEntryActions = {
  onEdit: () => void;
  onCancelEdit: () => void;
  onRemoveParty: () => void;
  onNavigateToVehicleMakeEditPage: () => void;
  onNavigateToVehicleColorEditPage: () => void;
};

type PartyEntryEditableListItemProps = PartyEntryCommonProps &
  PartyEntryActions & {
    inlineEdit?: boolean;
    isEditing: boolean;
  } & {
    vehicle: PartyEntryDataEntry['vehicle'];
  };

type PartyEntryFooterProps = Pick<
  PartyEntryActions,
  'onEdit' | 'onRemoveParty'
>;

type PartyEntryEditorProps = Pick<
  PartyEntryActions,
  | 'onCancelEdit'
  | 'onNavigateToVehicleMakeEditPage'
  | 'onNavigateToVehicleColorEditPage'
> &
  Pick<PartyEntryCommonProps, 'icon'> & {
    vehicle: PartyEntryDataEntry['vehicle'];
  };

export const PartyEntryEditableListItem = ({
  description,
  icon,
  isEditing,
  onCancelEdit,
  onNavigateToVehicleMakeEditPage,
  onNavigateToVehicleColorEditPage,
  onEdit,
  onRemoveParty,
  title,
  vehicle,
  inlineEdit,
}: PartyEntryEditableListItemProps) => {
  return (
    <div className="rounded-lg border border-gray-300 mb-3">
      {isEditing && inlineEdit ? (
        <PartyEntryEditor
          onCancelEdit={onCancelEdit}
          onNavigateToVehicleMakeEditPage={onNavigateToVehicleMakeEditPage}
          onNavigateToVehicleColorEditPage={onNavigateToVehicleColorEditPage}
          vehicle={vehicle}
          icon={icon}
        />
      ) : (
        <>
          <PartyEntryHeader
            icon={icon}
            title={title}
            description={description}
          />
          <PartyEntryFooter onEdit={onEdit} onRemoveParty={onRemoveParty} />
        </>
      )}
    </div>
  );
};

const PartyEntryHeader = ({
  description,
  icon,
  title,
}: PartyEntryCommonProps) => {
  return (
    <div className="flex items-center justify-left px-3 py-2 w-full border-b border-gray-300">
      {icon}
      <div
        className="flex flex-col justify-start pl-3"
        style={{ textAlign: 'left' }}
      >
        <span className="text-gray-600 text-sm">{title}</span>
        <span className="font-bold text-gray-600 text-sm">{description}</span>
      </div>
    </div>
  );
};

const PartyEntryFooter = ({ onEdit, onRemoveParty }: PartyEntryFooterProps) => {
  return (
    <div className="divide-x flex items-center">
      <div className="w-1/2 py-2 flex justify-center">
        <button
          type="button"
          className="w-full py-2 -my-2 -mx-4 text-gray-600 text-sm justify-center align-center flex flex-row gap-1"
          onClick={onEdit}
        >
          <IconFlatRenderer
            width={20}
            height={20}
            iconKey="ICON_FLAT_PENCIL_SQUARE"
            className="fill-gray-500 translate-y-[0.5px] scale-105"
          />

          <span>Edit</span>
        </button>
      </div>
      <div className="w-1/2 py-2 flex justify-center">
        <button
          type="button"
          className="w-full py-2 -my-2 -mx-4 text-gray-600 text-sm justify-center align-center flex flex-row gap-1"
          onClick={() => {
            onRemoveParty();
          }}
        >
          <IconFlatRenderer
            width={20}
            height={20}
            className="fill-gray-500 translate-y-[0.5px] scale-105"
            iconKey="ICON_FLAT_X"
          />
          <span>Remove</span>
        </button>
      </div>
    </div>
  );
};

const PartyEntryEditor = ({
  icon,
  onCancelEdit,
  onNavigateToVehicleColorEditPage,
  onNavigateToVehicleMakeEditPage,
  vehicle,
}: PartyEntryEditorProps) => {
  return (
    <div className="p-5">
      <div className="mb-4">
        <span className="flex justify-left text-gray-500 font-medium text-sm">
          Vehicle color
        </span>
        <button
          type="button"
          className="textbox flex flex-row justify-between align-center items-center"
          id="vehicle-color-edit-button"
          onClick={onNavigateToVehicleColorEditPage}
        >
          <div className="flex flex-row gap-1 align-middle items-center">
            <div className="text-gray-600 -translate-x-1">{icon}</div>
            <div className="text-gray-600 text-base font-normal">
              {capitalize(vehicle?.colorLabel || 'Unknown')}
            </div>
          </div>

          <div className="text-progressive-100">
            <span className="text-sm font-medium">Edit</span>
          </div>
        </button>
      </div>

      <div className="mb-6">
        <span className="flex justify-left text-gray-500 font-medium text-sm">
          Vehicle make
        </span>
        <button
          type="button"
          className="textbox flex flex-row justify-between align-center items-center"
          id="vehicle-make-edit-button"
          onClick={onNavigateToVehicleMakeEditPage}
        >
          <div className="text-gray-600 text-base font-normal">
            {vehicle?.make ?? 'Unknown'}
          </div>
          <div className="text-progressive-100 text-sm font-medium">Edit</div>
        </button>
      </div>

      <div className="w-full">
        <button
          type="button"
          className="btn btn-blue m-0 !p-2 justify-center w-1/2"
          onClick={onCancelEdit}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};
