import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { uploadMediaToS3 } from '@assured/utilities/src/uploadMediaToS3';

import { config } from '../../config';
import { useMobileDetect, useTenantConfig } from '../../hooks';
import ExampleIllustration from '../elements/ExampleIllustration';
import ProgressBar from '../ProgressBar';

import type {
  StepComponentFC,
  StepComponentSharedProps,
  UploadStepComponentSpec,
} from '@assured/step-renderer';
const VideoRecorder = React.lazy(() => import('../VideoRecorder'));

interface PreviewableFile extends File {
  preview: string;
  exifData: any;
}
interface UploadValueInner {
  source: string;
  lastModifiedAt: string | null;
  exifData: string;
  [key: string]: any;
}
type UploadValue = UploadValueInner | (UploadValueInner | { id: string })[];

interface UploadProps
  extends StepComponentSharedProps<
    UploadStepComponentSpec,
    UploadValue | null
  > {
  forceSubmit: () => void;
  additionalButtons: React.ReactNode;
}

const Upload: StepComponentFC<UploadProps> = ({
  step_component,
  primaryValue,
  updateValue,
  forceSubmit,
  className,
  additionalButtons,
  workflowChannel,
  onSidekickCommand,
}) => {
  const { isMobile } = useMobileDetect();

  const [files, setFiles] = useState<PreviewableFile[]>([]);
  const [progress, setProgress] = useState(0);
  const [uploadedData, setUploadedData] = useState<any[]>([]);
  const [userConfirmed, setUserConfirmed] = useState(false);
  const [dispatchedUpload, setDispatchedUpload] = useState(false);
  const tenantConfig = useTenantConfig();

  const uploadFile = (files: File[]) => {
    const setNewFile = (file: PreviewableFile) => {
      setFiles(existingFiles => [...existingFiles, file]);
    };

    uploadMediaToS3({
      files,
      serverEndpoint: config.endpoint,
      onPreprocessComplete: setNewFile,
      setProgress,
      onBatchFinished: onFinish,
      onError,
      mode: step_component.mode,
    });
  };

  const { getRootProps, getInputProps, rejectedFiles, isDragActive } =
    useDropzone({
      onDrop: acceptedFiles => {
        uploadFile(acceptedFiles);
      },
      accept: step_component.mode === 'image' ? 'image/*' : undefined,
      multiple: step_component.multiple || false,
    });

  const onError = (e: string) => {
    console.log(e);
    window.alert(JSON.stringify(e));
  };

  const onFinish = (data: any) => {
    setUploadedData(d => d.concat([data]));
  };

  useEffect(() => {
    if (
      userConfirmed &&
      uploadedData.length &&
      uploadedData.length === files.length &&
      !dispatchedUpload
    ) {
      const values = uploadedData.map(d => {
        const file =
          files.find(f => d.filename.indexOf(f.name) !== -1) || files[0];
        return {
          ...step_component.value,
          source: d.filename,
          lastModifiedAt: file.lastModified
            ? new Date(file.lastModified).toISOString()
            : null,
          exifData: JSON.stringify(file.exifData),
        };
      });
      setDispatchedUpload(true);
      updateValue(
        step_component.field,
        step_component.multiple
          ? (
              step_component.existing_hidden_values || ([] as UploadValue)
            ).concat(values)
          : values[0],
      );
    }
  }, [userConfirmed, uploadedData, files, dispatchedUpload]);

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [files],
  );

  if (workflowChannel === 'sidekick') {
    return (
      <div className={classNames(className, 'mt-4')}>
        <button
          onClick={() => {
            // Send sidekick backend request
            onSidekickCommand?.({ type: 'DIGITAL_REQUEST_MEDIA' });
            // Skip to next step
            forceSubmit();
          }}
          className="btn btn-blue"
        >
          Send media request
        </button>
        <div className="">
          <button className="btn btn-subtle" onClick={forceSubmit}>
            Never mind
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className={classNames(className, 'mt-4')}>
      {files.length === 0 ? (
        <React.Fragment>
          {step_component.example_illustration && (
            <ExampleIllustration type={step_component.example_illustration} />
          )}
          {step_component.mode === 'video' ? (
            <VideoRecorder
              className="mb-6 rounded-md overflow-hidden"
              onSubmit={(blob, extension) => {
                setUserConfirmed(true);
                uploadFile([new File([blob], `video${extension}`)]);
              }}
            />
          ) : (
            <div
              {...getRootProps()}
              className={classNames(
                'py-4 rounded border-4 border-dashed border-cool-gray-400 text-cool-gray-600 cursor-pointer bg-cool-gray-100 outline-none w-full h-full focus:outline-none focus:border-blue-300 focus:shadow-outline transition ease-in-out duration-150',
                isDragActive && 'bg-cool-gray-200',
              )}
              style={{ paddingLeft: '22%', paddingRight: '22%' }}
            >
              <input
                aria-labelledby="upload-label"
                tabIndex={0}
                {...getInputProps()}
              />
              {isDragActive ? (
                <p id="upload-label">Drop the image here...</p>
              ) : (
                <p id="upload-label">
                  {isMobile
                    ? `Tap here to take or upload ${
                        step_component.multiple
                          ? 'one or more pictures'
                          : 'a picture'
                      }`
                    : `Drag and drop ${
                        step_component.multiple
                          ? 'one or more photos'
                          : 'a photo'
                      } here, or click to select`}
                </p>
              )}
            </div>
          )}
          {(
            Array.isArray(step_component.existing_source)
              ? step_component.existing_source.length
              : step_component.existing_source
          ) ? (
            <button
              className="mt-4 btn btn-subtle mx-0 w-full block"
              onClick={forceSubmit}
            >
              Use previously uploaded
              {step_component.existing_source &&
              step_component.existing_source.length > 1
                ? ' images'
                : ' image'}
              {(Array.isArray(step_component.existing_source)
                ? step_component.existing_source
                : [step_component.existing_source]
              ).map(
                source =>
                  source && (
                    <img
                      key={source}
                      src={source}
                      className="h-20 mt-2 mx-auto"
                    />
                  ),
              )}
            </button>
          ) : null}
          {!step_component.unskippable ? (
            <button
              className="mt-4 btn btn-subtle mx-0 py-2 w-full block"
              data-testid="manuallyEnterData"
              onClick={forceSubmit}
            >
              {step_component.skip_label ||
                `I can't ${isMobile ? 'take' : 'upload'} ${
                  step_component.multiple ? 'these photos' : 'this photo'
                }`}
            </button>
          ) : null}
          {additionalButtons}
        </React.Fragment>
      ) : (
        <div className="outline-none">
          {step_component.mode !== 'video' ? (
            files.map(file => (
              <img
                key={file.name}
                src={file.preview}
                className="h-48 my-6 mx-auto border shadow-xl"
              />
            ))
          ) : (
            <div className="my-6" />
          )}
          {userConfirmed ? (
            <div>
              <div className="text-sm text-cool-gray-600 mb-2">
                Uploading {step_component.mode === 'video' ? 'video' : 'photo'}
                {files.length > 1 ? 's' : ''}...
              </div>
              <ProgressBar progress={progress} className="w-64 mx-auto" />
            </div>
          ) : (
            <button
              onClick={() => setUserConfirmed(true)}
              className="btn btn-blue"
            >
              Upload photo{files.length > 1 ? 's' : ''}
            </button>
          )}
        </div>
      )}
    </div>
  );
};
export default Upload;
