import { PartyEntryListItem } from './PartyEntryListItem';
import {
  PartyEntryButtonValue,
  PartyEntryDataEntry,
  PartyEntryListButtons,
} from '@assured/step-renderer/types/step-components/PartyEntry';
import { IconEmojiRenderer } from '@assured/design-system/src/components/Icon/IconEmojiRenderer';
import { capitalize } from '@assured/utilities/src/capitalize';

export const PartyEntryList = ({
  parties,
  footer,
  buttons,
  updateValue,
  field,
}: {
  parties: Partial<PartyEntryDataEntry>[];
  footer?: string;
  buttons: PartyEntryListButtons;
  updateValue: (
    field: string | undefined,
    value: PartyEntryButtonValue,
  ) => void;
  field?: string;
}) => {
  const items = parties.map((party, index) => {
    return (
      <>
        {index > 0 && <hr className="border-gray-200" />}
        <PartyEntryListItem
          icon={getPartyIcon(party)}
          title={getTitle(party)}
          description={getDescription(party)}
        />
      </>
    );
  });

  return (
    <div className="pt-6">
      {items}
      <h1 className="text-cool-gray-600 ClaimWorkflowInner mb-6 text-lg pt-6">
        {footer}
      </h1>
      {getPrimaryButton(buttons, updateValue, field)}
      {getSecondaryButton(buttons, updateValue, field)}
    </div>
  );
};

const getPartyIcon = (party: Partial<PartyEntryDataEntry>) => {
  if (party.partyType === 'PEDESTRIAN') {
    return <div className="h-[20px] w-[20px]">🚶</div>;
  }

  if (party.partyType === 'BICYCLE') {
    return <div className="h-[20px] w-[20px]">🚲</div>;
  }

  if (!party.vehicle?.color || party.vehicle.color === 'OTHER') {
    return (
      <IconEmojiRenderer width={20} height={20} iconKey="ICON_EMOJI_OTHER" />
    );
  }

  return (
    <div
      className="h-[20px] w-[20px] rounded-md border"
      style={{ backgroundColor: party.vehicle.color }}
    />
  );
};

const getTitle = (party: Partial<PartyEntryDataEntry>) => {
  if (party.partyType === 'PEDESTRIAN') {
    return 'Pedestrian';
  }

  if (party.partyType === 'BICYCLE') {
    return 'Bicyclist';
  }

  return 'Vehicle';
};

const getDescription = (party: Partial<PartyEntryDataEntry>) => {
  if (party.partyType === 'PEDESTRIAN' || party.partyType === 'BICYCLE') {
    return party.name || 'Unknown name';
  }

  if (!party.vehicle?.color && !party.vehicle?.make) {
    return 'Unknown vehicle';
  }

  if (!party.vehicle?.make && party.vehicle.color) {
    return `${capitalize(party.vehicle.colorLabel || '')} vehicle`;
  }

  if (!party.vehicle?.color && party.vehicle.make) {
    return `${party.vehicle.make}`;
  }

  return `${capitalize(party.vehicle.colorLabel || '')} ${party.vehicle.make}`;
};

const getPrimaryButton = (
  buttons: PartyEntryListButtons,
  updateValue: (
    field: string | undefined,
    value: PartyEntryButtonValue,
  ) => void,
  field?: string,
) => {
  const onClick = () => {
    updateValue(field, buttons.primaryButton.value);
  };

  return (
    <button
      type="button"
      key={JSON.stringify(buttons.primaryButton.value)}
      className="rounded-lg border border-gray-300 w-full py-4 px-6 mb-3 text-white text-base font-medium justify-center align-center"
      style={{ backgroundColor: '#0077B3' }}
      onClick={onClick}
    >
      <span>{buttons.primaryButton.label}</span>
    </button>
  );
};

const getSecondaryButton = (
  buttons: PartyEntryListButtons,
  updateValue: (
    field: string | undefined,
    value: PartyEntryButtonValue,
  ) => void,
  field?: string,
) => {
  if (buttons.secondaryButton) {
    const buttonValue = buttons.secondaryButton.value;
    const onClick = () => {
      if (buttons.secondaryButton) updateValue(field, buttonValue);
    };

    return (
      <button
        type="button"
        key={JSON.stringify(buttons.secondaryButton.value)}
        className="rounded-lg border border-gray-300 w-full py-4 px-6 text-gray-500 text-base justify-center align-center"
        onClick={onClick}
      >
        <span>{buttons.secondaryButton.label}</span>
      </button>
    );
  }

  return null;
};
