import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import { SearchIcon } from '@heroicons/react/solid';

import { CommercialDriverEntry } from './CommercialDriverEntry';
import { CommercialVehicleEntry } from './CommercialVehicleEntry';

import type {
  SelectOrCreateStepComponentSpec,
  StepComponentSharedProps,
} from '@assured/step-renderer';
import { noticeError } from '@assured/telemetry/src/common';

interface ExtendedSelectOrCreateProps
  extends StepComponentSharedProps<SelectOrCreateStepComponentSpec, any> {
  forceSubmit: () => void;
}

const ExtendedSelectOrCreate: React.FC<ExtendedSelectOrCreateProps> = ({
  step_component,
  updateValue,
  className,
  forceSubmit,
  primaryValue,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<any[]>([]);

  const selectedOption = step_component.options.find(option => {
    return step_component.create.fields.every((f, _, fields) => {
      return option?.[f.key] === primaryValue?.[f.key];
    });
  });

  useEffect(() => {
    if (searchTerm.length > 0) {
      import('fuzzyset.js')
        .then(({ default: FuzzySet }) => {
          const entries = step_component.options.map(o => {
            return Object.values(o).join(' ');
          });
          const fuzzySet = FuzzySet(entries);
          const results = fuzzySet.get(searchTerm, undefined, 0);
          const matchedEntries = results
            ?.filter(
              // For demo: exact match only
              r => r[1].toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1,
            )
            .map(r => r[1])
            .slice(0, 5);

          updateValue(step_component.field, null);

          setSearchResults(
            matchedEntries?.map(v =>
              step_component.options.find(o =>
                v.includes(o.vin ?? o.employeeId),
              ),
            ) ?? [],
          );
        })
        .catch(e => {
          noticeError(e);
          // eslint-disable-next-line no-console
          console.error(e);
          setSearchResults([]);
        });
    } else {
      setSearchResults([]);
    }
  }, [searchTerm]);

  return (
    <div className={classNames(className, 'mt-4')}>
      <div className="flex rounded-md shadow-sm">
        <div className="relative flex flex-grow items-stretch focus-within:z-10">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <input
            className="block pl-10 py-1.5 px-4 border-cool-gray-300 border-solid border-2 rounded-lg w-full focus:outline-none focus:shadow-outline-blue text-sm leading-6"
            autoFocus
            placeholder={
              step_component.extended_mode_format === 'commercial_vehicle'
                ? `Search by license plate, VIN, make/model...`
                : step_component.extended_mode_format === 'commercial_driver'
                ? `Search by name, employee ID, license...`
                : `Search by anything...`
            }
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
          />
        </div>
      </div>
      <div className="mt-4">
        {searchTerm.length === 0 ? (
          <div className="text-cool-gray-500 text-sm font-medium">
            Type to search {step_component.options.length}{' '}
            {step_component.extended_mode_format === 'commercial_vehicle'
              ? 'vehicles on policy'
              : 'drivers on policy'}
            ...
          </div>
        ) : searchResults.length === 0 ? (
          <div className="text-cool-gray-500 text-sm font-medium">
            No results found, try another search.
          </div>
        ) : (
          <div className="flex flex-col gap-4">
            {searchResults.map(result => {
              if (
                step_component.extended_mode_format === 'commercial_vehicle'
              ) {
                return (
                  <CommercialVehicleEntry
                    entry={result}
                    selected={selectedOption?.vin === result.vin}
                    key={result.vin}
                    onClick={() => {
                      updateValue(step_component.field, result);
                    }}
                  />
                );
              } else if (
                step_component.extended_mode_format === 'commercial_driver'
              ) {
                return (
                  <CommercialDriverEntry
                    entry={result}
                    selected={selectedOption?.employeeId === result.employeeId}
                    key={result.employeeId}
                    onClick={() => {
                      updateValue(step_component.field, {
                        ...result,
                        seat: 'DRIVER',
                      });
                    }}
                  />
                );
              }

              return <div>{JSON.stringify(result)}</div>;
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default ExtendedSelectOrCreate;
