import React, { useEffect, useState } from 'react';

import { Coordinate } from '@assured/step-renderer/types/step-components/additional';

import Label from './Label';
import LocationDeviceInput from './Location/LocationDeviceInput';
import LocationManualInput from './Location/LocationManualInput';

import type {
  StepComponentControlsBackHookProps,
  StepComponentFC,
  StepComponentSharedProps,
  StepComponentShowsPrefillProps,
  LocationStepComponentSpec,
} from '@assured/step-renderer';

const MODE_UNSELECTED = null;
const MODE_DEVICE = 'DEVICE_GPS';
const MODE_MANUAL = 'MANUAL';
const MODE_MEDIA_EXIF = 'MEDIA_EXIF';
const MODE_FINE = 'FINE';

const DEMO_LOCATION = {
  collectionMode: 'DEVICE_GPS',
  latitude: 37.44220803300987,
  longitude: -122.1612180732146,
};

interface LocationOnSubmitValue extends Partial<Coordinate> {
  addressText?: string | null;
  apartmentNumber?: string | null;
}

interface LocationValue extends LocationOnSubmitValue {
  collectionMode?: Coordinate | string | null;
}

type LocationProps = StepComponentSharedProps<
  LocationStepComponentSpec,
  LocationValue | null
> &
  StepComponentControlsBackHookProps &
  StepComponentShowsPrefillProps & { forceSubmit: () => void };

const Location: StepComponentFC<LocationProps> = ({
  step_component,
  primaryValue,
  updateValue,
  className,
  showsPrefill,
  registerBackHook,
  removeBackHook,
  forceSubmit,
  workflowChannel,
}) => {
  const isSidekick = workflowChannel === 'sidekick';

  const [mode, setMode] = useState(
    isSidekick
      ? MODE_MANUAL
      : step_component.mode || step_component.initial_mode || null,
  );

  const onSubmit = (location: LocationOnSubmitValue) => {
    console.log('On Submit', location);
    updateValue(step_component.field, {
      collectionMode: mode,
      ...location,
    });
  };

  useEffect(() => {
    const backHook = () => {
      if (
        (mode === MODE_DEVICE || mode === MODE_MANUAL) &&
        mode !== step_component.initial_mode
      ) {
        setMode(step_component.initial_mode || null);
        return true;
      }
      return false;
    };
    registerBackHook(backHook);
    return () => removeBackHook(backHook);
  }, [mode]);

  let el = null;

  if (mode === MODE_UNSELECTED) {
    el = (
      <div className="mt-4 flex flex-col">
        <button
          data-testid="primaryAction"
          className="btn btn-blue"
          onClick={() => setMode(MODE_DEVICE)}
        >
          Use device GPS location
        </button>
        <button
          className="mt-2 btn btn-subtle"
          onClick={() => setMode(MODE_MANUAL)}
          data-testid="secondaryAction"
        >
          Manually enter location
        </button>
        {step_component.skip_label ? (
          <button className="mt-2 btn btn-subtle" onClick={() => forceSubmit()}>
            {step_component.skip_label}
          </button>
        ) : null}
      </div>
    );
  }

  if (mode === MODE_DEVICE || mode === MODE_MEDIA_EXIF) {
    el = (
      <LocationDeviceInput
        initialLocation={step_component.existing_location}
        attemptGeolocate={mode !== MODE_MEDIA_EXIF}
        noInteraction={mode === MODE_MEDIA_EXIF}
        onBack={() => setMode(MODE_UNSELECTED)}
        onManual={() => setMode(MODE_MANUAL)}
        onSubmit={onSubmit}
      />
    );
  }

  if (mode === MODE_FINE) {
    el = (
      <LocationDeviceInput
        fine={true}
        attemptGeolocate={false}
        mustSubmit={true}
        initialLocation={step_component.existing_location}
        onSubmit={onSubmit}
        skipLabel={step_component.skip_label}
        onSkip={forceSubmit}
      />
    );
  }

  if (mode === MODE_MANUAL) {
    el = (
      <LocationManualInput
        includeAddressComponents={false}
        autoSubmitInline={!!step_component.label}
        noApartmentEntry={step_component.no_apartment_entry}
        noGpsEntry={step_component.no_gps_entry || isSidekick}
        skipLabel={step_component.skip_label}
        onSkip={forceSubmit}
        initialAddressText={step_component.existing_address_text}
        onDevice={() => setMode(MODE_DEVICE)}
        onSubmit={onSubmit}
        expected_countries={step_component.expected_countries}
      />
    );
  }

  return (
    <div className={className}>
      {step_component.label ? (
        <>
          <Label step_component={step_component} />
          <div className="-mt-2">{el}</div>
        </>
      ) : (
        <>
          {mode === MODE_MANUAL && showsPrefill ? (
            <div
              className="btn btn-magic mt-4 py-2 mx-0"
              onClick={() => {
                onSubmit(DEMO_LOCATION);
              }}
            >
              ✨ Use demo location ✨
            </div>
          ) : null}
          {el}
        </>
      )}
    </div>
  );
};

export default Location;
