import type {
  StepComponentFC,
  StepComponentSharedProps,
  YourInvolvementConfirmationStepComponentSpec,
} from '@assured/step-renderer';

import React from 'react';
import Seatmap from '../Seatmap';
import { PartyEntryListItem } from './PartyEntry/PartyEntryListItem';
import { twMerge } from 'tailwind-merge';
import { SvgYourInvolvementBicyclist } from '@assured/design-system/src/components/Svg/SvgYourInvolvementBicyclist';
import { SvgYourInvolvementPedestrian } from '@assured/design-system/src/components/Svg/SvgYourInvolvementPedestrian';
import { SvgYourInvolvementWitness } from '@assured/design-system/src/components/Svg/SvgYourInvolvementWitness';
import { SvgYourInvolvementVehicleUnknownSeat } from '@assured/design-system/src/components/Svg/SvgYourInvolvementVehicleUnknownSeat';
import { SvgYourInvolvementVehicleUninvolvedOwner } from '@assured/design-system/src/components/Svg/SvgYourInvolvementVehicleUninvolvedOwner';
import { SvgYourInvolvementVehicleUninvolvedNonOwner } from '@assured/design-system/src/components/Svg/SvgYourInvolvementVehicleUninvolvedNonOwner';
import { VisualizationType } from '@assured/step-renderer/types/step-components/YourInvolvementConfirmation';

type YourInvolvementConfirmationProps = StepComponentSharedProps<
  YourInvolvementConfirmationStepComponentSpec,
  string[] | string
>;

const getVehicleColorIcon = (label_color: string | null | undefined) => {
  const iconClassName = 'w-[20px] h-[20px] border-2 border-gray-200 rounded';
  let styleAttribute = {};
  if (label_color) {
    styleAttribute = {
      'background-color': label_color.toLowerCase(),
    };
  }
  return <div style={{ ...styleAttribute }} className={iconClassName} />;
};

const getListItemIcon = (
  step_component: YourInvolvementConfirmationStepComponentSpec,
): string | JSX.Element => {
  switch (step_component.visualization_type) {
    case 'pedestrian':
      return '👀';
    case 'bicyclist':
      return '🚲';
    case 'witness':
      return '👀';
    case 'seatmap':
    case 'unknownSeat':
    case 'uninvolvedOwner':
    case 'uninvolvedNonOwner':
      return getVehicleColorIcon(
        step_component.visualization_data?.label_color,
      );
    default:
      return '';
  }
};

const getListItemLabel = (
  step_component: YourInvolvementConfirmationStepComponentSpec,
): string => {
  switch (step_component.visualization_type) {
    case 'pedestrian':
      return 'Pedestrian';
    case 'bicyclist':
      return 'Bicyclist';
    case 'witness':
      return 'Witness';
    case 'seatmap':
    case 'unknownSeat':
    case 'uninvolvedOwner':
    case 'uninvolvedNonOwner':
      return step_component.visualization_data?.label_text || '';
    default:
      return '';
  }
};

const getSeatlabel = (
  step_component: YourInvolvementConfirmationStepComponentSpec,
): JSX.Element | undefined => {
  let seatLabel = '';
  if (
    [
      VisualizationType.SEATMAP,
      VisualizationType.UNINVOLVED_OWNER,
      VisualizationType.UNINVOLVED_NON_OWNER,
    ].includes(step_component.visualization_type)
  ) {
    seatLabel = step_component.visualization_data?.seat_label || '';
  } else if (step_component.visualization_type === 'unknownSeat') {
    seatLabel = 'Unknown seat';
  }
  return seatLabel ? (
    <h2 className="pt-4 pb-1 pl-8 pr-8 font-bold text-gray-600 text-sm text-center">
      {seatLabel}
    </h2>
  ) : undefined;
};

const getGraphicElement = (
  step_component: YourInvolvementConfirmationStepComponentSpec,
): JSX.Element | undefined => {
  switch (step_component.visualization_type) {
    case 'pedestrian':
      return <SvgYourInvolvementPedestrian className="w-full" />;
    case 'bicyclist':
      return <SvgYourInvolvementBicyclist className="w-full" />;
    case 'witness':
      return <SvgYourInvolvementWitness className="w-full" />;
    case 'unknownSeat':
      return <SvgYourInvolvementVehicleUnknownSeat className="w-full" />;
    case 'uninvolvedOwner':
      return <SvgYourInvolvementVehicleUninvolvedOwner className="w-full" />;
    case 'uninvolvedNonOwner':
      return <SvgYourInvolvementVehicleUninvolvedNonOwner className="w-full" />;
    case 'seatmap':
      return step_component.visualization_data?.vehicle_layout ? (
        <Seatmap
          small
          labeled // Adds extra vertical padding for the labeled version of the seatmap
          vehicleLayout={step_component.visualization_data.vehicle_layout}
          allowAnySeat={step_component.visualization_data.allow_any_seat}
          disabledSeats={step_component.visualization_data.disabled_seats}
          selected={step_component.visualization_data.selected_seats}
        />
      ) : undefined;
    default:
      return undefined;
  }
};

const YourInvolvementConfirmation: StepComponentFC<
  YourInvolvementConfirmationProps
> = ({ step_component, className }) => {
  return (
    <div className={twMerge('border-gray-200 border rounded-xl', className)}>
      <PartyEntryListItem
        className="border-gray-200 border-b py-4"
        icon={getListItemIcon(step_component)}
        title="" // unused in this context
        description={getListItemLabel(step_component)}
      />
      {getSeatlabel(step_component)}
      <div className="object-center p-8">
        {getGraphicElement(step_component)}
      </div>
    </div>
  );
};

YourInvolvementConfirmation.stepConfig = {
  manualSubmit: true,
};

export default YourInvolvementConfirmation;
