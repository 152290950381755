import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import { Coordinate } from '@assured/step-renderer/types/step-components/additional';

import { geolocationPermissionStatus } from '../../../hooks/useGeolocation';
import Modal from '../../Modal';
import LocationMap from './LocationMap';

const FAIL_MESSAGE_DELAY = 5 * 1000; // ms

interface LocationDeviceProps {
  initialLocation?: Coordinate | null;
  attemptGeolocate?: boolean;
  noInteraction?: boolean;
  fine?: boolean;
  mustSubmit?: boolean;
  onManual?: () => void;
  onSubmit?: (location: Coordinate) => void;
  onBack?: () => void;
  skipLabel?: string;
  onSkip?: () => void;
}
const LocationDeviceInput: React.FC<LocationDeviceProps> = ({
  initialLocation,
  attemptGeolocate,
  noInteraction,
  fine,
  mustSubmit,
  onManual,
  onSubmit,
  onBack,
  skipLabel,
  onSkip,
}) => {
  const [location, setLocation] = useState<Coordinate | null>(
    initialLocation ? initialLocation : null,
  );
  const [showFailMessage, setShowFailMessage] = useState<boolean>(false);

  const canConfirm = !!location;
  const onConfirm = () => {
    if (location) {
      onSubmit &&
        onSubmit({
          latitude: location.latitude,
          longitude: location.longitude,
        });
    }
  };

  useEffect(() => {
    const id = setTimeout(() => {
      setShowFailMessage(true);
    }, FAIL_MESSAGE_DELAY);
    return () => clearTimeout(id);
  }, []);

  useEffect(() => {
    if (attemptGeolocate) {
      navigator.geolocation.getCurrentPosition(e => {
        geolocationPermissionStatus.granted = true;
        setLocation({
          latitude: e.coords.latitude,
          longitude: e.coords.longitude,
        });
      });
    }
  }, []);

  return (
    <div className="mt-6">
      {showFailMessage && !location && (
        <Modal
          title="We're having trouble determining your location."
          body={
            <div>
              Your location services may be disabled, or perhaps you haven't
              granted us permission to access your location.
            </div>
          }
          actions={[
            {
              title: 'Enter manually instead',
              primary: true,
              className: 'sm:order-1',
              onClick: () => onManual && onManual(),
            },
            {
              title: 'Back',
              className: 'sm:order-0',
              onClick: () => onBack && onBack(),
            },
          ]}
        />
      )}
      <div
        className="rounded overflow-hidden"
        style={{
          height: 200,
          width: '100%',
          position: 'relative',
        }}
      >
        <LocationMap
          center={location || { latitude: 37.1, longitude: -95.7 }}
          zoom={location ? (fine ? 19 : 18) : 0}
          mapTypeId={fine ? 'hybrid' : null}
          loaded={!!location}
          interactive={!noInteraction}
          onChange={(loc: Coordinate) => {
            console.log('onChange', loc);
            setLocation({ latitude: loc.latitude, longitude: loc.longitude });
          }}
        />
      </div>
      {!noInteraction ? (
        <div className="sm:block hidden mt-3 text-sm text-gray-500">
          Click and drag the map to reposition the pin.
        </div>
      ) : null}
      <div className="mt-6 md:flex md:flex-row md:flex-row-reverse justify-center">
        <button
          className={classNames('btn btn-blue', !canConfirm && 'btn-disabled')}
          disabled={!canConfirm}
          onClick={onConfirm}
          data-testid="primaryAction"
        >
          Confirm location
        </button>
        {!mustSubmit ? (
          <button
            data-testid="manuallyEnterData"
            className="btn btn-subtle"
            onClick={noInteraction ? onBack : onManual}
          >
            {noInteraction ? 'Change location' : 'Enter manually'}
          </button>
        ) : skipLabel ? (
          <button className="btn btn-subtle" onClick={onSkip}>
            {skipLabel}
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default LocationDeviceInput;
