import type { StepComponentSpec } from '../stepComponentSpec';
import { VehicleSeatmapFields } from './VehicleSeatmap';

export enum VisualizationType {
  SEATMAP = 'seatmap', // vehicle seatmap with known seat
  UNKNOWN_SEAT = 'unknownSeat', // vehicle, but seat is unknown. Car with "?" graphic
  UNINVOLVED_OWNER = 'uninvolvedOwner', // vehicle, but reporting owner was not an occupant
  UNINVOLVED_NON_OWNER = 'uninvolvedNonOwner', // vehicle, but reporting non-owner was not an occupant
  PEDESTRIAN = 'pedestrian',
  BICYCLIST = 'bicyclist',
  WITNESS = 'witness',
}

interface YourInvolvementConfirmationVisualizationData
  extends Partial<VehicleSeatmapFields> {
  label_color?: string | null;
  label_text?: string | null;
  seat_label?: string | null;
}

export interface YourInvolvementConfirmationFields {
  visualization_type: VisualizationType;
  visualization_data?: YourInvolvementConfirmationVisualizationData;
}

type YourInvolvementConfirmationStepComponentSpec = StepComponentSpec<
  YourInvolvementConfirmationFields,
  'your_involvement_confirmation'
>;

export default YourInvolvementConfirmationStepComponentSpec;
