import { IconKey } from '@assured/shared-types/icon/emoji/IconKey';

import { Variants } from 'framer-motion';
import { CatastropheSeverity, CatType } from '../../generatedX/graphql';

export const SELECTIVE_PRIMARY_COLOR = '#FFCF31';
export const SELECTIVE_SECONDARY_COLOR = '#1B5073';
export const TICKER_MAX_SCREEN_WIDTH = 600;

export type CatTypesWithoutMap = 'EARTHQUAKE' | 'DUST_STORM' | 'WINDSTORM';
export const CAT_TYPES_WITHOUT_MAP: CatType[] = ['EARTHQUAKE', 'DUST_STORM'];

export const CatastropheEmojiMap: Record<CatType, IconKey> = {
  BLIZZARD: 'ICON_EMOJI_SNOW_CLOUD',
  DUST_STORM: 'ICON_EMOJI_DUST_STORM',
  EARTHQUAKE: 'ICON_EMOJI_EARTHQUAKE',
  FLOOD: 'ICON_EMOJI_RAIN_CLOUD',
  HAILSTORM: 'ICON_EMOJI_HAIL_CLOUD',
  HURRICANE: 'ICON_EMOJI_HURRICANE',
  ICE_STORM: 'ICON_EMOJI_SNOW_CLOUD',
  LIGHTNING_STORM: 'ICON_EMOJI_RAIN_THUNDER_CLOUD',
  TORNADO: 'ICON_EMOJI_TORNADO',
  WILDFIRE: 'ICON_EMOJI_WILDFIRE',
  WINDSTORM: 'ICON_EMOJI_WIND_STORM',
  WINTER_STORM: 'ICON_EMOJI_SNOW_CLOUD',
};

export const CatastropheSeverityLabelMap: Record<CatastropheSeverity, string> =
  {
    HIGH: 'High',
    MEDIUM: 'Medium',
    LOW: 'Low',
  };

export const CatastropheNounMap: Record<CatType, string> = {
  BLIZZARD: 'storm',
  DUST_STORM: 'storm',
  EARTHQUAKE: 'earthquake',
  FLOOD: 'flood',
  HAILSTORM: 'storm',
  HURRICANE: 'hurricane',
  ICE_STORM: 'storm',
  LIGHTNING_STORM: 'storm',
  TORNADO: 'tornado',
  WILDFIRE: 'fire',
  WINDSTORM: 'storm',
  WINTER_STORM: 'storm',
};

export const mainContentAnimationConfig: Variants = {
  initial: {
    y: '10vh',
    opacity: 0,
  },
  final: {
    y: '0vh',
    opacity: 1,
    transition: {
      type: 'spring',
      mass: 0.2,
    },
  },
};

export const sidebarAnimationConfig: Variants = {
  initial: {
    y: '-10vh',
    opacity: 0,
  },
  final: {
    opacity: 1,
    y: '0vh',
    transition: {
      type: 'spring',
      mass: 0.2,
    },
  },
};

export const todoContentAnimationConfig: Variants = {
  initial: {
    opacity: 0,
  },
  final: {
    opacity: 1,
    transition: {
      type: 'spring',
      mass: 0.4,
    },
  },
};

export const initialLoadingAnimationConfig: Variants = {
  initial: {
    opacity: 0,
  },
  final: {
    opacity: 1,
    transition: {
      type: 'spring',
      mass: 100,
    },
  },
};

export const videoBackgroundAnimationConfig: Variants = {
  initial: {
    opacity: 0,
  },
  final: {
    opacity: 1,
    transition: {
      type: 'spring',
      mass: 100,
    },
  },
};

export const welcomeAnimationConfig: Variants = {
  initial: {
    opacity: 0,
  },
  final: {
    opacity: 1,
    transition: {
      type: 'spring',
      mass: 20,
    },
  },
};

export const trackerAnimationConfig: Variants = {
  hidden: {
    y: '-100%',
    transition: {
      type: 'spring',
      mass: 0.1,
    },
  },
  visible: {
    y: 0,
    transition: {
      type: 'spring',
      mass: 0.1,
    },
  },
};

export const CatBackgroundOverlays: Record<CatType, string> = {
  BLIZZARD: 'bg-gradient-to-b from-black/[0.5] to-black',
  DUST_STORM: 'bg-gradient-to-t from-black via-black/[0.5]',
  EARTHQUAKE: 'bg-gradient-to-b from-black/[0.5] to-black',
  FLOOD: 'bg-gradient-to-b from-black/[0.50] to-black',
  HAILSTORM: 'bg-gradient-to-b from-black/[0.5] to-black',
  HURRICANE: 'bg-gradient-to-b from-black/[0.5] to-black',
  ICE_STORM: 'bg-gradient-to-b from-black/[0.5] to-black',
  LIGHTNING_STORM: 'bg-gradient-to-b from-black/[0.50] to-black',
  TORNADO: 'bg-gradient-to-b from-black/[0.5] to-black',
  WILDFIRE: 'bg-gradient-to-b from-black/[0.25] to-black',
  WINDSTORM: 'bg-gradient-to-b from-black/[0.5] to-black',
  WINTER_STORM: 'bg-gradient-to-b from-black/[0.5] to-black',
};

export const CatVideoSpeeds: Record<CatType, number> = {
  BLIZZARD: 1,
  DUST_STORM: 0.5,
  EARTHQUAKE: 1,
  FLOOD: 0.5,
  HAILSTORM: 0.5,
  HURRICANE: 0.5,
  ICE_STORM: 1,
  LIGHTNING_STORM: 0.5,
  TORNADO: 0.25,
  WILDFIRE: 0.5,
  WINDSTORM: 0.5,
  WINTER_STORM: 1,
};

const backgroundVideos = {
  dustStormDesktop: require('url:./backgrounds/dust-storm-desktop.mp4'),
  dustStormMobile: require('url:./backgrounds/dust-storm-mobile.mp4'),
  floodDesktop: require('url:./backgrounds/flood-desktop.mp4'),
  floodMobile: require('url:./backgrounds/flood-mobile.mp4'),
  lightningDesktop: require('url:./backgrounds/lightning-desktop.mp4'),
  lightningMobile: require('url:./backgrounds/lightning-mobile.mp4'),
  rainDesktop: require('url:./backgrounds/rain-desktop.mp4'),
  rainMobile: require('url:./backgrounds/rain-mobile.mp4'),
  tornadoDesktop: require('url:./backgrounds/tornado-desktop.mp4'),
  tornadoMobile: require('url:./backgrounds/tornado-mobile.mp4'),
  wildfireDesktop: require('url:./backgrounds/wildfire-desktop.mp4'),
  wildfireMobile: require('url:./backgrounds/wildfire-mobile.mp4'),
  winterPrecipitationDesktop: require('url:./backgrounds/winter-precipitation-desktop.mp4'),
  winterPrecipitationMobile: require('url:./backgrounds/winter-precipitation-mobile.mp4'),
};

export type CatTypesWithVideos =
  | 'BLIZZARD'
  | 'DUST_STORM'
  | 'FLOOD'
  | 'HAILSTORM'
  | 'HURRICANE'
  | 'ICE_STORM'
  | 'LIGHTNING_STORM'
  | 'TORNADO'
  | 'WILDFIRE'
  | 'WINDSTORM'
  | 'WINTER_STORM';

export const CatVideos: Record<CatTypesWithVideos, [string, string]> = {
  BLIZZARD: [
    backgroundVideos.winterPrecipitationMobile,
    backgroundVideos.winterPrecipitationDesktop,
  ],
  DUST_STORM: [
    backgroundVideos.dustStormMobile,
    backgroundVideos.dustStormDesktop,
  ],
  FLOOD: [backgroundVideos.floodMobile, backgroundVideos.floodDesktop],
  HAILSTORM: [backgroundVideos.rainMobile, backgroundVideos.rainDesktop],
  HURRICANE: [backgroundVideos.rainMobile, backgroundVideos.rainDesktop],
  ICE_STORM: [
    backgroundVideos.winterPrecipitationMobile,
    backgroundVideos.winterPrecipitationDesktop,
  ],
  LIGHTNING_STORM: [
    backgroundVideos.lightningMobile,
    backgroundVideos.lightningDesktop,
  ],
  TORNADO: [backgroundVideos.tornadoMobile, backgroundVideos.tornadoDesktop],
  WILDFIRE: [backgroundVideos.wildfireMobile, backgroundVideos.wildfireDesktop],
  WINDSTORM: [backgroundVideos.tornadoMobile, backgroundVideos.tornadoDesktop],
  WINTER_STORM: [
    backgroundVideos.winterPrecipitationMobile,
    backgroundVideos.winterPrecipitationDesktop,
  ],
};
